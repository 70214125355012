.nft-popup {
  overflow: auto;

  .modal-header {
    @media screen and (max-width: 768px) {
      margin-bottom: 16px !important;
    }
  }

  .modal-content {
    @media screen and (max-width: 425px) {
      padding: 18px 16px !important;
    }
  }

  .nft-popup__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;

    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }

  .nft-animation__wrapper {
    width: 340px;
    height: 340px;
    position: relative;
    margin-right: 30px;

    @media screen and (max-width: 991px) {
      margin-right: 0;
      margin-bottom: 30px;
      align-self: center;
    }

    @media screen and (max-width: 375px) {
      width: 300px;
      height: 300px;
    }

    @media screen and (max-width: 320px) {
      width: 275px;
      height: 275px;
    }
  }

  .nft-animation__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .nft-content {
    flex: 1;
  }

  @media screen and (max-width: 768px) {
    align-items: flex-start !important;
  }
}
