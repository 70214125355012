.minting {
  .minting-content {
    .minting-title {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 0.5rem;

      &_LIGHT {
        color: #212529
      }
    
      &_DARK {
        color: #FFFFFF;
      }
    }

    .loading-wrapper {
      .tmp-parent {
        justify-content: flex-start;
      }
    }
  }
}
