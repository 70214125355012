@import '../../Constants/Styles/colors.scss';
.bid-ask-popup-wrapper  {

    .bid-ask-popup {
        &__row {
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;
            background-color: transparent;
            transition: background-color 0.15s linear;
    
            .select-with-label, > div  {
                width: 45%;
            }
    
            .bid-ask-segment {
                width: 50%;
                text-align: center;
                padding: .5rem 0;
                font-size: 12px;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
    
                &.color-scheme {
                    &-DARK {
                        color: $white0;
                        border: 1px solid rgba(93, 95, 124, 0.15);

                        &.BUY {
                            background-color: #11282E;
                            color: #739D85;
                            transition: all 0.15s linear;
                            &-active {
                                background-color: #0BA456;
                                color: #FFFFFF;
                            }
                        }

                        &.SELL {
                            background-color: #320F25;
                            color: #944057;
                            transition: all 0.15s linear;
                            &-active {
                                background-color: #D21325;
                                color: #FFDACE;
                            }
                        }
                    }
                    &-LIGHT {
                        color: #0a0a1e;
                        border: 1px solid rgba(93, 95, 124, 0.15);

                        &.BUY {
                            background: #CBD9D9;
                            color: #739D85;
                            transition: all 0.15s linear;
                            &-active {
                                background-color: #0BA456;
                                color: #FFFFFF;
                            }
                        }

                        &.SELL {
                            background-color: #E6C4CA;
                            color: #7D3B3F;
                            transition: all 0.15s linear;
                            &-active {
                                background-color: #D21325;
                                color: #FFDACE;
                            }
                        }
                    }
                }
                &.active {
                    background-color: rgba(93, 95, 124, 0.15);
                }
            }
        }
        &__column {
            display: flex;
            flex-direction: column;
        }
    }

    .selector-label {
        &.color-scheme {
            &-DARK {
                color: $white0;
            }
            &-LIGHT {
                color: #0a0a1e;
            }
        }
    }

    .actions-button {
        display: flex;
        justify-content: center;
    }
}

.bid-ask-popup {
    .modal-header {
        margin-bottom: 0.5rem!important;

        .modal-title {
            font-size: 14px;
        }
    }

    .modal-dialog {
        min-width: 355px!important;
    }

    &.wide {
        .modal-dialog {
            min-width: 390px!important;
        }
    }
}

.label-small-indent {
    margin-bottom: 10px!important;
}

.bid-ask__btn-width {
    button {
        min-width: auto!important;
    }
    .dropdown-menu {
        .dropdown-item {
            text-transform: lowercase!important;
        }
    }
}
