@import '../../Constants/Styles/colors.scss';

.Governance {
    position: relative;
    display: flex;
    margin-bottom: 26px;

    .ContainerWithCollapse {
        margin-bottom: 8px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        display: none;
        width: 100%;

        div {
            display: inline-block;
            width: 18px;
            height: 16px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 16px;
        }

        span {
            font-family: Montserrat;
            font-weight: 500;
            font-size: 22px;
            line-height: 20px;
        }
    }
    &__table {
        min-width: 528px;
        margin-right: 32px;
        
        &.custom-table {
            .table {
                &-body {
                    .table {
                        &-row {
                            .table-cell {
                                button {
                                    margin-left: 0;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.custom-table {
            .table {
                &-body {
                    .table {
                        &-row {
                            .table-cell {
                                button {
                                    width: auto;
                                    margin: 0 auto;
                                }
                            }
                        }
                    }
                }
            }
        }
        .table {
            &-body {
                .table {
                    &-row {
                        padding-left: 1vw;
                        margin-bottom: 20px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        .table-cell {
                            button {
                                width: auto;
                            }
                        }
                    }
                }
            }
            &-arrows {
                width: calc(88px / 2);
                height: calc(64px / 2);
                margin-left: calc(calc(100% - 100px - 35% - 195px) + 35% + 100px + 42px);
                margin-bottom: 15px;
                @media (max-width: 1200px) {
                    margin-left: calc(calc(100% - 100px - 35% - 195px) + 35% + 100px + 80px);
                }
                @media (max-width: 1024px) {
                    margin-left: calc(calc(100% - 100px - 35% - 195px) + 35% + 100px + 70px);
                }
                @media (max-width: 950px) {
                    margin-left: calc(calc(100% - 100px - 35% - 195px) + 35% + 100px + 60px);
                }
                @media (max-width: 768px) {
                    margin-left: calc(205px + 100px + calc(100% - 100px - 35% - 150px) + 50px);
                }
                @media (max-width: 480px) {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
        .cell {
            padding: 8px;

            &:nth-child(1) {
                min-width: 85px;
                width: 100px;
            }
            &:nth-child(2) {
                min-width: 205px;
                width: 35%;
            }
            &:nth-child(3) {
                min-width: 80px;
                width: calc(100% - 100px - 35% - 195px);
                span {
                    word-break: break-word;
                }
            }
            &:nth-child(4) {
                min-width: 195px;
                width: 195px;
                justify-content: center;
                span {
                    width: 100%;
                    text-align: center;
                    justify-content: center;
                }
            }

            .table-gov-image {
                max-width: 65px;
                max-height: 65px;
            }

            span {
                font-weight: 600;
                font-size: 18px;
                line-height: 20px;

                white-space: normal;
                &.label {
                    display: block;
                    width: 100%;
                    
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 20px;
                }
            }
        }
    }
    &__right {
        .description-block {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            margin-bottom: 15px;

            &.color-scheme {
                &-DARK {
                    color: $white0;
                }

                &-LIGHT {
                    color: $darkblue1;
                }
            }
        }

        ul {
            li {
                display: flex;
                margin-bottom: 12px;
                img {
                    margin-right: 14px;
                }
                a {
                    font-family: Montserrat;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
    }
    &__button-spoiler {
        display: inline-block;
        padding: 20px;
    }

    &__table-row {
        display: flex;
        flex-direction: column;

        &.row {
            display: flex;
            flex: 1;
            margin: 0 !important;
        }

        .row {
            &__body {
                display: flex;
                margin-bottom: 21px;
            }
            &__title {
                display: block;

                font-family: Montserrat;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;

                margin-bottom: 19px;
            }
            &__image {
                width: 56px;
                height: 56px;
                margin-right: 16px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            &__wrap-buttons {
                display: flex;
                justify-content: center;
            }
            &__hidden {
                display: none;
            }
        }
    }

    .content-block {
        margin: 0;
        .text-label {
            font-family: Montserrat;
            font-weight: normal;
            font-size: 10px;
            line-height: 12px;

            margin-bottom: 2px;
        }
        .text-content {
            span {
                font-family: Montserrat;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
            }
        }
    }
    .wrap-arrows {
        width: 96px;
    }
}

.modal.Governance__stake-withdraw-modal {
    .modal-dialog {
        width: 100%;
        max-width: 344px;
    }
}

.governance-wrapper {
    position: relative;
    height: calc(100vh - 215px);
    width: 100%;
    margin-top: 25px;

    @media (max-width: 768px) {
        margin-top: 7px;
    }
}

.wrap-opium-block {
    display: flex;
    padding: 24px 32px;
    .description-block {
        margin-bottom: 19px;
        .title {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
        }
    }

    .opium-block-content {
        display: flex;
    }

    .wrap-buttons {
        display: flex;
        justify-content: center;
    }

    .wrap-arrows {
        display: flex;
        justify-content: center;

        img {
            width: 28px
        }
    }

}

.governance-proposal-content {
    display: flex;
    min-height: 152px;
    padding: 21px 0;
    padding-top: 0;
    flex-direction: column;

    .ContainerWithCollapse {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .nav {
        width: 100%;
    }
    .nav-tabs {
        .nav-link {
            font-size: 14px;
            padding: 7px 0;
        }
    }

    .tab-content {
        width: 100%;
        margin-top: 10px;
        .tab-pane {
            &.active {
                &.color-scheme {
                    &-DARK {
                        background-color: transparent;
                    }

                    &-LIGHT {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    .governance-block {
        display: flex;
        width: 100%;
        min-height: 96px;

        &__datetime {
            display: flex;
            width: 47px;
            margin-right: 14px;
            align-items: center;

            & > span {
                display: block;
                width: 100%;
                text-align: center;
                white-space: nowrap;

                font-family: Montserrat;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;

                transform: rotate(-90deg);
            }
        }

        &__datetime-mobile {
            display: none;
        }

        &__progress-bar {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-width: 248px;

            margin-right: 33px;

            .progress-wrapper {
                margin-bottom: 12px;

                .progress-bar-title {
                    margin-bottom: 1px;
                }

                .progress-label {
                    white-space: nowrap;
                }
            }
        }

        &__description {
            display: flex;
            flex-direction: column;
            justify-content: center;

            padding: 20px 0;
            min-width: 279px;

            margin-right: 33px;

            .title {
                font-family: Montserrat;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;

                margin-bottom: 12px;
            }

            .ended-time {
                font-family: Montserrat;
                font-weight: normal;
                font-size: 10px;
                line-height: 12px;
            }
        }

        &__action-btn {
            flex: 1;
            padding-right: 30px;

            div {
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: flex-end;
            }
            
            button {
                min-width: 123px;
            }
        }
    }
}

@media (max-width: 1200px) {
    .Governance {
        &__title {
          display: block;
          margin-bottom: 25px;
        }

        &__right {
            padding: 4px 0;
            ul {
                li {
                    margin-bottom: 15px;
                }
            }
        }
        &__table {
            // width: 590px;
            min-width: auto;
            margin-right: 16px;
            max-width: 334px;
            width: 100%;
        }
        &__table-row {
            &.row {
                margin-right: 16px !important;
            }
            .row {
                &__body {
                    flex-wrap: wrap;
                    margin-bottom: 21px;
                }
                &__title {
                    margin-bottom: 0;
                }
                &__image {
                    width: 32px;
                    height: 32px;
                    margin-right: 8px;
                }
                &__wrap-buttons {
                    justify-content: flex-start;
                }
                &__title-block {
                    display: flex;
                    align-items: center;
                    .content-block {
                        display: none;
                    }
                }
                &__hidden {
                    display: block;
                    width: 100%;
                    padding-top: 6px;
                }
            }
        }
    }

    .governance-proposal-content {
        .governance-block {
            &__datetime {
                width: 55px;
                margin-right: 17px;
            }
            &__description {
                min-width: auto;
                width: 40.423%;

                margin-right: 10px;
            }
            &__progress-bar {
                min-width: auto;
                width: 23.832%;

                margin-right: 10px;
            }
            &__action-btn {
                padding-right: 15px;
            }
        }
    }

    .governance-wrapper {
        padding: 0 2rem;
        margin-left: -18px;
        width: calc(100% + 18px);

        @media (max-width: 425px) {
            padding: 0;

            @media (max-width: 320px) {
                padding: 0;
            }
        }
    }

    .wrap-opium-block {
        padding: 24px 16px;
        .wrap-arrows {
            display: none;
        }

        .opium-block-content {
            width: 50%;
        }
    }
}

@media (max-width: 767px) and (orientation: portrait) {
    .Governance {
        &__right {
            display: none;

            &.Governance__table-body {
                display: flex;
                flex-direction: column;
                padding: 0 16px;
            }
        }
        &__table {
            width: 100%;
            max-width: none;
            margin: 0;

            .cell {
                justify-content: center;
            }
            &.custom-table {
                .table {
                    &-body {
                        .table {
                            &-row {
                                .table-cell {
                                    &:nth-child(4) {
                                        justify-content: center;
                                    }
                                    .btn {
                                        .btn__label {
                                            justify-content: center;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &__title {
            display: none;
        }

        &__table-row {
            &.row {
                display: inline-block;
                width: calc(50% - 8px);
                margin: 0 !important;
                &:first-child {
                    margin-right: 16px !important;
                }
            }

            .row {
                &__image {
                    margin-right: 9px;
                }
                &__wrap-buttons {
                    width: 100%;
                }
            }
        }

        &__button-spoiler {
            padding: 20px 50px;
        }
    }

    .governance-proposal-content {
        width: 100%;

        .ContainerWithCollapse .accordion {
            padding: 22px 14px;
        }

        .nav {
            display: none;
        }

        .governance-block {
            flex-direction: column;
            align-items: stretch;

            &__datetime {
                width: 100%;
                margin: 0;
                margin-bottom: 9px;
                span {
                    transform: none;
                    
                    br {
                        content: '';
                        &:after {
                            content: ' ';
                        }
                    }
                }
            }

            &__description {
                width: 100%;
                padding: 0;
                margin: 0;
                margin-bottom: 17px;

                .title {
                    margin-bottom: 13px;
                }
            }

            &__progress-bar {
                width: 100%;
                margin: 0;
                margin-bottom: 10px;
            }

            &__action-btn {
                width: 100%;
                padding: 0;
                div {
                    justify-content: center;
                }

                button {
                    min-width: 122px;
                }
            }
        }

        .tab-content {
            margin: 0;
            .ContainerWithCollapse {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .governance-wrapper {
        width: 100%;
        margin: 0;
        max-height: none;
    }

    .mobile-content-btn {
        text-align: center;
    }
}

@media (max-width: 812px) and (max-height: 425px) and (orientation: landscape) {
    .governance-wrapper {
        margin: 0;
        width: 100%;
        // height: auto;
        max-height: none;
    }

    .governance-proposal-content {
        .nav {
            display: none;
        }
    }

    .Governance {
        margin: 0;
        &__table {
            min-width: 382px;
        }
    }
}

.Mining {
    .mining-block-heading {
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        margin-top: 40px;
        margin-bottom: 2rem;

        @media (max-width: 1200px) {
            display: none;

            @media (max-width: 768px) {
                margin-top: 50px;
                margin-bottom: 20px;

                @media (max-width: 425px) {
                    display: block;
                }
              }
          }

        &.color-scheme {
            &-DARK {
                color: #fff;
            }

            &-LIGHT {
                color: $darkblue1;
            }
          }
    }
}
