$gray1: #1E1E22;
$gray2: #4F4F4F;
$gray3: #78787A;
$gray4: #BDBDBD;
$gray4_10: hsla(0, 0%, 74%, 0.1);
$gray4_15: hsla(0, 0%, 74%, 0.15);
$gray4_25: hsla(0, 0%, 74%, 0.25);
$gray5: #D2D2D3;
$gray6: #EDEDF0;
$gray6_30: #ededf04d;
$gray7: #F1F2F4;
$gray8: #C1C1C1;
$gray9: #F4F4F4;
$gray10: #8B8B8B;
$red0: #FF5A5A;
$red0_20: hsla(0, 84%, 65%, 0.2);
$red0_30: hsla(0, 84%, 65%, 0.3);
$red0_40: hsla(0, 84%, 65%, 0.4);
$red0_50: hsla(0, 84%, 65%, 0.5);
$red1: #ff0000;
$red2: #ad0101;
$red3: #EB5757;
$blue1: #197CD8;
$blue1_lighten: lighten(#197CD8, 20%);
$blue1_darken: darken(#197CD8, 50%);
$blue2: #227CD7;
$blue2_lighten: rgba(#227CD7, .6);
$blue3: #BBEBFA;
$blue4: #103868;
$blue3_30: #bbebfa4d;
$blue_4: #24559F;
$blue5: #142649;
$yellow3: #ffe070;
$yellow3_darken: darken(#ffe070, 60%);
$yellow3_40: hsla(47, 100%, 72%, 0.4);
$yellow3_50: hsla(47, 100%, 72%, 0.5);
$yellow3_60: hsla(47, 100%, 72%, 0.6);
$yellow3_70: hsla(47, 100%, 72%, 0.7);
$yellow3_80: hsla(47, 100%, 72%, 0.8);
$green1: #36D69C;
$green1_darken: darken(#36D69C, 40%);
$green1_20: hsla(158, 87%, 39%, 0.2);
$green1_30: hsla(158, 87%, 39%, 0.3);
$green1_40: hsla(158, 87%, 39%, 0.4);
$green1_50: hsla(158, 87%, 39%, 0.5);
$green3: #B3F3DC;
$white0: white;
$white1: #f8f8f9;
$black0: #000000;
$black1: #121212;
$black2: #121212;
$black3: #121212;
$darkgray_20: hsla(240, 50%, 8%, 0.2);
$darkgray1: #0A0A1E;
$darkgray1_darken: darken(#080818, 100%);
$darkgray1_40: hsla(240, 50%, 8%, 0.4);
$darkgray2: #3A3A55;
$darkgray3: #6B6F83;
$darkgray4: #A2A7B6;
$darkgray5: #CACFD8;
$darkgray6: #EBEDF3;
$darkgray7: #767a8a;
$darkred_30: #ff5a5a4d;
$darkblue1: #0a0a1e;
$darkblue2: #141429;
$darkblue3: #151538;
$darkblue4: #27273b;
$darkblue5: #3A3A55;
$darkblue6: #4E3E7A;
$transparent0: #ffffff00;
$transparent1: hsla(256, 33%, 36%, 0.2);
$transparent2: hsla(227, 52%, 68%, 0.1);
$transparent3: #00000040;
$pink: #F6029C;
$lightgreen: #2ECD94;
