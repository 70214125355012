.AuthorizationPopUp {
  .modal-dialog {
    max-width: 400px !important;
    margin: 0 auto;
  }
}

.terms-popup-wrapper {
  display: flex;
  flex-direction: column;
  // justify-content: space-evenly;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  & > * {
    margin-bottom: 16px;
  }

  ul { 
    li {
      list-style-type: circle;
      margin-bottom: 10px;
      list-style-position: inside;
    }
  }

  .terms-popup-links {
    a {
      color: unset;
      text-decoration: underline;
    }
  }

  .terms-popup-buttons-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
      min-width: 150px;

      @media (max-width: 359px){
        & {
            min-width: 100px;
          }
      }
    }
  }
}

@media (max-width: 767px) {
  .terms-popup-wrapper {
    width: unset;
  }

}

@media (max-width: 520px), (max-width: 812px) and (orientation: landscape) {
  .terms-popup-wrapper {
    width: unset;
  }
}