.toggle-button {

    svg {
        transform: rotate(0deg);
        transition: transform .3s ease;

        path {
            transition: fill .3s ease;
        }
    }

    &.up {
        svg {
            transform: rotate(180deg);
        }
    }
}