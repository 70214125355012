@import '../../Constants/Styles/colors.scss';

.tools {
  .Switcher {
    position: relative;
    top: -3px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 23px;
    user-select: none;
    label {
      white-space: nowrap;
    }
    span {
      padding-top: 3px;
    }
    &.color-scheme {
      &-DARK {
        .toggle-switch {
          box-shadow: inset 0 0 0 1px $white0 !important;
        }
      }
      &-LIGHT {
        .toggle-switch {
          box-shadow: inset 0 0 0 1px $darkblue1 !important;
        }
      }
    }
  }

  .tools {
    &__languages {
      margin-bottom: 19px;
      white-space: nowrap;
      button {
        position: relative;
  
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-decoration: none;

        background: transparent;
        border: 0;
        padding: 0;
  
        margin-right: 15px;
  
        transition: color .3s ease;
  
        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          width: 100%;
          height: 1px;
          bottom: -7px;
  
          transform-origin: right;
          transform: scaleX(0);
  
          transition: transform .3s ease;
        }
  
        &.active {
          &:after {
            transform: scaleX(1);
          }
        }
  
        &:hover {
          text-decoration: none;
          &:after {
            transform-origin: left;
            transform: scaleX(1);
          }
        }
  
        &:last-child {
          margin-right: 0;
        }
      }
  
      &.color-scheme {
        &-DARK {
          button {
            color: rgba($white0, .5);
            &:after {
              background-color: $white0;
            }
            &.active {
              color: rgba($white0, 1);
            }
            &:hover {
              color: rgba($white0, 1);
            }
          }
        }
        &-LIGHT {
          button {
            color: rgba($darkblue1, .5);
            &:after {
              background-color: $darkblue1;
            }
            &.active {
              color: rgba($darkblue1, 1);
            }
            &:hover {
              color: rgba($darkblue1, 1);
            }
          }
        }
      }
    }

    &__links {
      display: flex;
      flex-direction: column;
      margin-bottom: 23px;
    }

    &__kyc {
      margin-bottom: 15px;
    }
  
    &__switcher {
      white-space: nowrap;
      .tools {
        &__themeSwitcher {
          display: inline-flex;
          align-items: center;
          margin-right: 19px;
          user-select: none;
          .tools__themeSwitcher {
            &--icon {
              position: relative;
              display: inline-block;
              width: 16px;
              height: 16px;
              margin-right: 8px;
              svg {
                position: absolute;
                top: 0;
                left: 0;
                transform: scale(.7);
  
                opacity: 0;
  
                transition: opacity .5s ease, transform .5s ease;
                &.dark {
                  &.active {
                    transform: scale(1);
                    opacity: 1;
                  }
                }
                &.light {
                  &.active {
                    transform: scale(1);
                    opacity: 1;
                  }
                }
              }
            }
            &--label {
              display: inline-block;
              color: $white0;
            }
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
      &.color-scheme {
        &-DARK {
          .tools {
            &__themeSwitcher {
              .tools__themeSwitcher {
                &--label {
                  color: $white0;
                }
              }
            }
          }
        }
        &-LIGHT {
          .tools {
            &__themeSwitcher {
              .tools__themeSwitcher {
                &--label {
                  color: $darkblue1;
                }
              }
            }
          }
        }
      }
    }
  }
}
.kyc-button {
  border-color: #2ECD94 !important;
  color: #2ECD94 !important;
  opacity: .7 !important;
}