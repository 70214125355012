.IntroPage__wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.IntroPage {
  padding-top: 24.5vh;

  &__container {
      padding-left: 100px;
      padding-right: 100px;
  }

  &__logo {
      margin-bottom: 60px;

      img {
          width: 100%;
          max-width: 823px;
      }
  }

  &__description {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500;
      // font-size: 36px;
      font-size: 2.572rem;
      line-height: 28px;

      color: #FFFFFF;

      padding-left: 13px;
      margin-bottom: 75px;
  }
}


.btn.intro-button {
  &-how {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

.intro-button-start {
  margin-right: 2rem;
}

@media (max-width: 480px) {
  .intro-button {
    padding: 0;
  }
  
  .intro-button-how {
    margin-top: 2rem;
  }
  .intro-button-start {
    margin: 0 2rem;
  }
}

@media (max-width: 768px) {
  .IntroPage {
    &__container {
      text-align: center;
      padding-left: 5vw;
      padding-right: 5vw;
    }

    &__description {
      font-size: 1.8rem;
      margin-bottom: 50px;
      padding: 0;
      text-align: center;
    }
  }
}

@media (max-width: 480px) {
  .IntroPage {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    padding-top: 24.5vh;
    height: 100%;
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      height: 100%;
      padding-left: 5vw;
      padding-right: 5vw;
      padding-bottom: 50%;
      .btn {
        min-height: 49px;
      }
    }
  }
  .intro-button {
    padding: 0;
  }
  
  .intro-button-how {
    margin-top: 2rem;
  }
  .intro-button-start {
    margin: 0 5rem;
  }
}

@media (max-height: 620px) and (max-width: 480px) {
  .IntroPage {
    padding-top: 15vh;
  }
}

