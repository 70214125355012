@import '../../Constants/Styles/colors.scss';
.content-block {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
  .text-label {
    font-size: 12px;
    line-height: 12px;
  }

  .text-content {
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;

    .opium-link {
      &.color-scheme {
        &-DARK { 
          a {
            color: $white0;
            font-weight: 800;
          }
        }
        &-LIGHT {
          a {
            color: $darkblue1;
            font-weight: 800;
          }
        }
      }
    }
  }

  &.horizontal {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    .text-label {
      text-align: left;
    }

    .text-content {
      text-align: right;
    }
  }
}


