@import '../../Constants/Styles/constants.scss';

.terms {
  background-color: color(gray5);
  padding: 2rem;
  border-radius: 15px;
  height: calc(100vh - 160px);
  
  font-size: 14px;
  line-height: 18px;
  * > strong {
    font-weight: 600;
  }
}
