@import '../../Constants/Styles/constants.scss';
@import '../../Constants/Styles/colors.scss';

.footer-wrapper {
  background: inherit;
}

.footer {
  padding: 20px 0;
  height: 50px;
  color: white;

  .copyright {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 11px;

    display: flex;
    align-items: center;
    justify-content: left;

    &.color-scheme {
      &-DARK {
        color: $white0;
      }

      &-LIGHT {
        color: $darkblue1;
      }
    }
  }
}

.slide-menu-header-container {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  .info-block {
    margin-top: 12px;
  }

  .main-menu-block {
    display: flex;
    flex-direction: row;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    // line-height: 20px;

}
}

/* *** Mobile Footer menu *** */
.slide-menu {
  display: none;
  // height: unset !important;
}

.mobile-wallet-address {
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  padding-bottom: 1px;
  img {
    width: 2rem;
  }
  &:hover {
    cursor: pointer;
  }

  &.color-scheme {
    &-DARK {

      &.active {
        border-bottom: 1px solid $white0;
      }

      &:hover {
        border-bottom: 1px solid $white0;
      }

      button {
        color: $white0;
      }
    }

    &-LIGHT {

      &.active {
        border-bottom: 1px solid $darkblue1;
      }

      &:hover {
        border-bottom: 1px solid $darkblue1;
      }

      button {
        color: $darkblue1;
      }
    }
  }

  button {
    border: 0;
    padding: 0;
    background: none;
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
  }

  img {
    display: block;
    margin-right: 16px;
  }
}

.mobile-wallet-disabled {
  opacity: .3;
  border-bottom: none !important;
}

.mobileWalletConnect {
  margin-right: 5px;
}

.mobileNetworkSwitch {
  &.color-scheme {
    &-DARK {
      button {
        display: flex;
        align-items: center;
        color: $white0;
        padding-bottom: 2px;
        &.active {
          border-bottom: 1px solid $white0;
        }

        &:hover {
          border-bottom: 1px solid $white0;
        }
      }

    }
    &-LIGHT {

      button {
        display: flex;
        align-items: center;
        color: $darkblue1;
        padding-bottom: 2px;

        &.active {
          border-bottom: 1px solid $darkblue1;
        }

        &:hover {
          border-bottom: 1px solid $darkblue1;
        }
      }
    }
  }
}

.AuthorizationPopUp {
  &__wallets {
    max-height: calc(189px + 16px);
    max-height: 100%;
    margin-bottom: 12px;
    overflow-y: auto;
    .AuthorizationPopUp {
      &__wallet {
        outline: none;
        background: transparent;
        color: inherit;

        display: flex;
        align-items: center;
        width: 100%;

        padding: 12px 30px;
        border-radius: 5px;
        border: 1px solid transparent;

        margin-bottom: 5px;

        transition: border-color .25s ease, opacity .25s ease;

        &:hover {
          cursor: pointer;
          border-color: $white0;
        }
      }
      &__icon {
        background-size: contain;
        background-repeat: no-repeat;
        width: 32px;
        height: 32px;
        margin-right: 33px;
      }
    }

    &.blocked {
      .AuthorizationPopUp {
        &__wallet {
          opacity: .5;
          pointer-events: none;
        }
      }
    }
  }

}

/* *** End mobile Footer menu *** */

@media (max-width: 767px), (max-width: 1024px) and (orientation: landscape)  {
  .footer {
    height: auto;
    &-wrapper {
      position: relative;
      display: block;
    }
    .copyright {
      position: absolute;
      right: 16px;
      bottom: 16px;
    }
  }

  .slide-menu {
    display: block;
    height: 90%;
    // overflow: auto;
    overflow-y: auto;
    
    @media (max-width: 359px) {
      & {
        
        left: 50%;
        width: 260px;
        margin-left: -130px;
        .header-block {
          padding: 0 6px 5px 6px;
        };
        
      }
    }
    
  }
}

@media (max-width: 520px), (max-width: 812px) and (orientation: landscape) {
  .footer {
    padding: 16px;
    .copyright {
      position: absolute;
      right: 16px;
      bottom: 23px;
    }
  }
}
