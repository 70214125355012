.constructor-popup-wrapper {

    .popup-main-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      font-family: 'Montserrat';
      font-style: normal;
    }
  }
  
  .constructor-popup {
  
    .modal-content {
      width: 344px;
    }
    .modal-header {
      margin-bottom: 0.5rem!important;
    }
    .actions-button {
      display: flex;
    }
  
    &.success {
      .modal-title {
        color: #2ECD94!important;
      }
    }
    &.error {
      .modal-title {
        color: #F6029C!important;
      }
    }
  }

  .constructor-popup-btn-LIGHT {
    background-color: white!important;

    &:hover {
      background-color: #212529!important;
    }
  }