@import '../../Constants/Styles/constants.scss';
@import '../../Constants/Styles/colors.scss';

.mining {
  &__wrapper {
    position: relative;
    width: 100%;

    .mining-no-data-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 20px;
      .mining-no-data-title {
        font-size: 16px;
        
        &.color-scheme-DARK {
          color: white;
        }
  
        &.color-scheme-LIGHT {
          color: #0A0A1E;
        }
      }
      .mining-no-data-description {
        font-size: 12px;
        margin-top: 5px;

        &.color-scheme-DARK {
          color: white;
        }
  
        &.color-scheme-LIGHT {
          color: #0A0A1E;
        }
      }
    }
  }
  &__title {
      display: none;
      width: 100%;
      margin-bottom: 25px;

      div {
          display: inline-block;
          width: 18px;
          height: 16px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          margin-right: 16px;
      }

      span {
          font-family: Montserrat;
          font-weight: 500;
          font-size: 22px;
          line-height: 20px;
      }
  }
  // &__controls {
  //   margin-bottom: 27px;
  // }
  &__tabs {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: calc(100% - 355px);
    .nav-tabs {
      padding-left: 28px;
      .nav-item {
        font-family: Montserrat;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        padding: 4px 0;
        margin-right: 36px;
      }
    }

    .CustomChart .recharts-layer .recharts-cartesian-axis-tick text tspan {
      font-size: 12px;
    }

    .tab-content {
      .tab-pane {
        &.active {
          &.color-scheme {
            &-DARK,
            &-LIGHT {
              background: transparent;
            }
          }
        }
      }
    }
  }
}

.mining-content-block {
  .content-items {
    width: 50%;
    .one-column-block {

    }

    .two-columns-block {

    }
  }
}

.mining-wrapper {
  position: relative;
  overflow: auto;
  height: calc(100vh - 209px);
  width: 100%;
}

.modal.Mining__stake-withdraw-modal {
  .modal-dialog {
      width: 100%;
      max-width: 344px;
  }
}

.page-layout.mining {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding-top: 14px;
}

.static-links-block {
  display: flex;
  flex-direction: row;
  .opium-link {
    margin-right: 15px;
    &.color-theme {
      &-DARK {
        a {
          color: $white0;
        }
      }

      &-LIGHT {
        a {
          color: $darkblue1;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .page-layout.mining {
    width: calc(100% + 16px);
    margin-left: -16px;
    padding-top: 7px;
  }

  .mining {
    &__title {
      display: block;
    }
    // &__controls {
    //   margin-bottom: 18px;
    // }
    &__tabs {
      .nav-tabs {
        justify-content: space-between;
        padding-left: 18px;
        .nav-item {
          margin-right: 0;
        }
      }
    }

    .FilterControls {
      .btn-selector {
        margin-right: 25px;

        &:after {
          bottom: -4px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .mining {
    &__tabs {
      display: none;
      max-width: none;
    }
    &__wrapper {
      max-height: none;
    }
  }
  .page-layout.mining {
    margin-left: 0;
    width: 100%;
    padding-top: 0;
  }
}

@media (max-width: 425px) {
  .mining {
    &__title {
      display: none;
      max-width: none;
    }
  }
}

@media (max-width: 812px) and (orientation: landscape) {
  .page-layout.mining {
    margin-left: 0;
    width: 100%;
    padding: 0;
    .FilterControls {
      .btn-selector {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        margin-right: 15px;
      }
    }
    .ContainerWithCollapse {
      margin-bottom: 7px;
    }
  }
  .mining {
    &__wrapper {
      max-height: none;
    }
    &__title {
      display: none;
    }
  }
}


#opium-tabs-component-tab-active-minings {
  font-size: 12px !important;
  margin-bottom: 10px;
}

#opium-tabs-component-tab-ended-minings {
  font-size: 12px !important;
  margin-bottom: 10px;
}

.mining-popup-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.minings-tabs {

  &.controlled-tabs_DARK {
    .controlled-tab {
      color: #fff;

      &.controlled-tab_is-active {
        border-bottom: 1px solid #fff!important;
      }
    }
  }

  &.controlled-tabs_LIGHT {
    .controlled-tab {
      color: #0A0A1E;

      &.controlled-tab_is-active {
        border-bottom: 1px solid #0A0A1E!important;
      }
    }
  }
  .controlled-tab {
    font-size: 12px;
    margin-bottom: 10px;
    border-bottom: 1px solid transparent!important;
    font-weight: 500!important;
    transition: all 0.15s linear;

    &:not(:last-child) {
      margin-right: 2em;
    }
  }
}