@import '../../../Constants/Styles/constants.scss';
@import '../../../Constants/Styles/colors.scss';


.login-popup-wrapper {
  .CheckBox {
    margin-bottom: 16px;
  }
  :last-child {
    margin-bottom: 30px;
  }
}

.AuthorizationPopUp {
  .modal-dialog {
    width: 342px !important;
  }

  .AuthorizationPopUp {
    &__wallets {
      // max-height: calc(189px + 16px);
      max-height: 100%;
      margin-bottom: 12px;
      overflow-y: auto;
      .AuthorizationPopUp {
        &__wallet {
          position: relative;
          outline: none;
          background: transparent;
          color: inherit;

          display: flex;
          align-items: center;
          width: 100%;

          padding: 12px 30px;
          border-radius: 5px;
          border: 1px solid transparent;

          margin-bottom: 5px;

          transition: border-color .25s ease, opacity .25s ease;

          &:hover {
            cursor: pointer;
            border-color: $white0;
          }

          &_disabled {
            filter: grayscale(100%);

            &:hover {
              cursor: not-allowed;
              border-color: transparent; 
            }
          }

          span {
            &.without-logo {
              width: 100%;
              text-align: center;
            }
          }

          span {
            &.wallet-title-block {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              
              .wallet-signature {
                margin-top: 7px;
                font-size: 10px;
              }
            }
          }
          
          &.--notSupported {
            opacity: 0.3;
          }
        }
        &__icon {
          background-size: contain;
          background-repeat: no-repeat;
          width: 32px;
          height: 32px;
          margin-right: 33px;
        }
      }

      &.blocked {
        .AuthorizationPopUp {
          &__wallet {
            opacity: .5;
            pointer-events: none;
          }
        }
      }
    }

    &__more {
      display: block;
      width: auto;
      outline: none;
      border: none;
      background: transparent;
      margin: 0 auto;

      text-decoration: underline;

      transition: color .25s ease;
      &:hover {
        text-decoration: none;
      }

      &.color-scheme {
        &-DARK {
            color: $white0;


            &:hover {
              color: rgba($white0, .7);
            }
          }
        &-LIGHT {
              &__more {
                color: $darkblue1;
              }

              &:hover {
                color: rgba($darkblue1, .7);
              }
          }
        }
    }

  }
}

.AuthorizationPopUp-terms {
  z-index: 10000;
  
  .modal-dialog {
    width: unset !important;
  }
}


.check-network-popup {
  .check-network-popup-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    font-size: 24px;
    img {
      width: 28px;
      margin-right: 10px;
    }
  }
  .modal-content {
    width: 80%;
    margin: auto;
  }

  .check-network-popup-text {
    line-height: 18px;
  }

  .check-network-popup-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 24px;
    .btn {
      width: 45%;
    }
  }
}