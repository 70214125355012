@import './constants.scss';
@import './colors.scss';

:root {
	--app-height: 100vh;
}

html {
	font-size: 14px;
	height: var(--app-height);
}

// body {
// 	background: radial-gradient(72.74% 182.65% at 85.89% 66.02%, #111132 2.27%, #0A0A1E 98.05%);
// }

button,
input {
	outline: none !important;
	outline-offset: none !important;
	box-shadow: none !important;

	&:focus,
	&:active {
		outline: none !important;
		outline-offset: none !important;
		box-shadow: none !important;
	}
}

#root {

	.app {
		.blurred {
			filter: blur(4px);
		}
		&.color-scheme {
			&-DARK {
				background-color: #0A0A1E;
				// background-image: radial-gradient(72.74% 182.65% at 85.89% 66.02%, #111132 2.27%, #0A0A1E 98.05%);
			}

			&-LIGHT {
				// background-image: radial-gradient(100% 244.84% at 100% 56.35%, #DEDEFE 0%, #FFFFFF 63.43%);
				background-color: #F6F6FF;
			}
		}
	}


	.main {
		flex-grow: 1;
		display: flex;
		flex-direction: row;
		height: 100%;
		& > .page-container {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			// height: 100%;
			width: calc(100% - #{$sideBarWidth});
			margin-left: $sideBarWidth;
			& > .main-content {
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;

				height: 100%;
				width: 100%;
			}
		}
	}
}

.page-container {
	width: 100%;
	margin: 0 auto;
	padding: 0 32px;
}

.anchor {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100% + 80px);
	pointer-events: none;
	z-index: 9999;
}

.custom-table {
	font-family: 'Montserrat', sans-serif;

	.table-body {
		.table-row {
			.table-cell {
				.text-block-content {
					height: 100%;
					display: flex;
					align-items: flex-start;
					flex-direction: column;
					justify-content: space-evenly;
					overflow: hidden;
					white-space: normal;
					&.unipoolinfo {
						width: 100%;
						justify-content: center;
						span {
							margin: 0;
						}
					}
					img {
						margin-bottom: -8px;
					}
					div {
						span {
							margin: 0 5px;
						}
						.phase-slash {
							margin: 0 -3px 0 2px;
						}
					}
					.child {
						span {
							margin: 0;
						}
						.line {
							width: 100%;
							span {
								margin-left: 4px;
							}
						}
						display: inline-block;
					}
				}
				.text-block {
					height: 100%;
					padding: 2rem 0;
					background-color: transparent;
					border: 1px solid transparent;
					display: flex;
					align-items: center;
					justify-content: space-evenly;
					flex-direction: column;
				}
				button {
					color: rgb(75, 75, 78);
					background-color: rgb(255, 255, 255);
					border-color: rgb(25, 124, 216);
					border-style: solid;
					margin: 1rem;
					width: 100%;
					white-space: pre-wrap;
					word-break: normal;
				}
			}
		}
	}
}

//.nav-tabs {
//	display: none;
//}

.turbo-intro-modal {
	.modal-dialog {
		max-width: 342px;
			.modal-content {
				border-radius: 20px;
				.modal-body {
					display: flex;
					.turbo-intro-content {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;

						img {
							margin-bottom: 28px;
						}

						.explore-btn {
							margin-top: 28px;
							text-align: center;
						}
					}
				}

			}
	}
}

.loader-wrapper {
	display: flex;
	justify-content: center;
	height: 100vh;
}

@media (max-width: breakpoint('laptopS')) {
	.main {
		.page-container {
			padding: 0 32px;
		}
  }

  .custom-table .table-head-cell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }
}

@media (max-width: 1200px) {
	#root {
		.main {
			& > .page-container {
				width: calc(100% - #{$sideBarTabletWidth});
				margin-left: $sideBarTabletWidth;
			}
		}
	}
}

@media (max-width: 768px), (max-width: 812px) and (max-height: 425px) and (orientation: landscape), (max-width: 1024px) and (orientation: landscape) {
	#root {
		.main {
			& > .page-container {
				width: 100%;
				margin-left: 0;
				padding: 0 16px;
			}
		}
	}

}

@media screen and (max-width: 480px) {
	#root {
		.main {
			padding-top: 16px;
			& > .page-container {
				margin-left: 0;
			}
		}
	}

	.custom-table {
		.table-body {
			.table-row {
				flex-direction: column;
				.table-cell {
					width: 100%;
					div {
						display: block;
						align-items: center;
						flex-wrap: wrap;
					}
					.table-cell {
						&__btn {
							display: flex;
						}
					}
					.tab-image-wrapper {
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.text-block {
						padding: 0.5rem 0;
					}
					button {
						margin-bottom: 5px;
					}
				}
			}
		}
	}
	.page-container {
		padding: 0;
		margin-left: 0;
	}
	// #root {
	// 	padding-top: 80px;
		// .main {
		// 	padding-top: 80px;
		// }
	// }
	.anchor {
		top: -80px;
	}
}

.turbo-intro-content {
	line-height: 20px;
}

.paragraph {
	font-size: 12px;
	line-height: 16px;
	color: #FFFFFF;
	margin-bottom: 1rem;

	&_LIGHT {
		color: #212529
	}

	&_DARK {
		color: #FFFFFF;
	}

	&_warning {
		padding: 12px 0;
		border-top: 1px solid #5D5F7C;
		border-bottom: 1px solid #5D5F7C;
		font-weight: bolder;

		.link-terms {
			color: #5D5F7C;
			font-weight: 700;
		}
	}
}

.error-message {
	font-size: 13px;
	color: $pink;
}

.-cbwsdk-css-reset .-cbwsdk-extension-dialog-box-top-description-text {
	background-color: #fafbfc!important;
}
