.notification-amount {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.notification-popup {

    .modal-dialog {
        .modal-content {
            max-width: 380px!important;
        }
    }


    .modal-header {
        .modal-title {
            color: #1EC992
        }
    }
}