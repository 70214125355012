@import '../../../../Constants/Styles/colors.scss';
$spinnerSize: 16px;

.DerivativeTable {
    padding-right: 1.5rem;

    &.no-filter {
        height: calc(100vh - 275px);
        @media (max-width: 768px) {
            height: calc(100vh - 310px);

            @media (max-width: 425px) {
                height: calc(100vh - 460px);
            }
        }
    }

    &.filter {
        height: calc(100vh - 320px);
        @media (max-width: 768px) {
            height: calc(100vh - 360px);

            @media (max-width: 425px) {
                height: calc(100vh - 498px);
            }
        }
    }
    
    @media (max-width: 1024px) {
        overflow: auto;

        @media (max-width: 425px) {
            padding-right: 5px;
        }
    }

    .derivative-table {
        thead {
            th {
                border-bottom: 1px solid #5D5F7C;
                width: 8.5%;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                color: #999BBC;
                padding: 15px 7px;

                @media (max-width: 1440px) {
                    width: 9%;

                    @media (max-width: 1200px) {
                        white-space: nowrap;
                    }
                }

                img {
                    cursor: pointer;
                    margin-left: 5px;
                }

                .derivative-table-content {
                    display: flex;
                    align-items: inherit;
                }

                &:nth-child(3) {
                    width: 22%;

                    @media (max-width: 1440px) {
                        width: 27%;
                    }
                }

                &:first-child {
                   width: 5%; 
                }

                &.color-scheme {
                    &-DARK {
                        &:nth-child(n + 7):not(:nth-child(n + 11)) {
                            background-color: rgba(20, 20, 60, 0.3);
                        }  
                    }
                    &-LIGHT {
                        &:nth-child(n + 7):not(:nth-child(n + 11)) {
                            background-color: rgba(20, 20, 60, 0.05);
                        }
                    }
                }

                &:nth-child(8), &:nth-child(9) {
                    width: 12%;

                    @media (max-width: 1024px) {
                        width: 14%;
                    }
                }

                &:nth-child(7), &:nth-child(10) {
                    white-space: nowrap;
                }
            }
        }

        tbody {
            td {
                font-size: 12px;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                vertical-align: middle; 
                padding: 15px 7px;
        
                .item-box {
                    width: 85px;
                    padding: 0 0.3rem;
                    box-sizing: border-box;
                    border-radius: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 400;
                    white-space: nowrap;
                }

                .item-green {
                    background: rgba(27, 162, 89, 0.2)!important;
                    border: none!important;
                    

                    &.color-scheme {
                        &-LIGHT {
                            color: $darkblue1!important;
                        }

                        &-DARK {
                            color: #1BA259!important;
                        }
                    }

                    &:hover {
                        color: white!important;
                        background: #1BA259!important;
                    }
                }

                .item-red {
                    background: rgba(209, 19, 37, 0.2)!important;
                    border: none!important;

                    &.color-scheme {
                        &-LIGHT {
                            color: $darkblue1!important;
                        }

                        &-DARK {
                            color: #D11325!important;
                        }
                    }

                    &:hover {
                        color: white!important;
                        background: #D11325!important;
                    }
                }

                .derivative-item-icon {
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                }

                .derivative-item-actions {
                    text-decoration: none;
                    font-weight: 700;
                    &.color-scheme {
                        &-DARK {
                            color: $white0;

                        }
                        &-LIGHT {
                            color: $darkblue1;
                        }
                    }

                    &:hover {
                        cursor: pointer;
                        color:#5d5f7c;
                    }

                    @media (max-width: 768px) {
                        white-space: nowrap;
                    }
                }

                .derivative-item-collateral {
                    font-size: 10px;
                    color: #999BBC;
                    margin-top: 12px;
                    width: 85px;
                    justify-content: center;
                    white-space: nowrap;
                    display: flex;
                    line-height: 10px;
                    min-height: 18px;

                    &.color-scheme {
                        &-DARK {
                            color: $white0;

                        }
                        &-LIGHT {
                            color: $darkblue1;
                        }
                    }

                    p {
                        display: flex;
                        margin: 0 !important;
                    }

                    @media (max-width: 1440px) {
                        flex-direction: column;
                        align-items: center;
                        min-height: 30px;
                    }
                }


                &:last-child {
                    padding-right: 20px;

                    img {
                        width: 24px;
                        height: 24px;
                    }

                    @media (max-width: 1024px) {
                        padding-right: 0;
                    }
                  }

                  &:nth-child(9) {
                    padding-right: 30px;
                  }

                // &:nth-child(n + 7):not(:nth-child(n + 10)) {
                //     background-color: rgba(20, 20, 60, 0.05);
                // }
            }
        }
    }
}

.explorer-tab-section {
    position: relative;

    .derivatives-tabs-anim-svg {
        position: absolute;
        right: 0;
        top: 1rem;
        cursor: pointer;
        background-color: transparent;
        border: none;
        transition: background-color 0.15s linear;
        border: 1px solid #5D5F7C;
        box-sizing: border-box;
        border-radius: 42px;
        width: 114px;
        height: 25px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: white;
        display: flex;
        align-items: center;
        padding-left: 10px;

        &:hover {
            background-color: #999BBC;
        }

        @media (max-width: 425px) {
            top: 0.5rem;
        }

        svg {
            position: absolute;
            right: 8px;
            top: 4px;
        }

        .derivatives-dash {
            stroke-dasharray: 47;
            stroke-dashoffset: 0;
            animation: dash 15s linear;
          }

          .auction-dash {
            stroke-dasharray: 47;
            stroke-dashoffset: 0;
            animation: dash 5s linear;
          }

        .derivatives-circle {
            stroke-dasharray: 47;
            stroke-dashoffset: 0;
            animation: circle-path 2.5s linear infinite;
            transform-origin: 50% 50%;
        }

        .circle-svg {
            animation: 2s linear infinite both circle;

            circle {
                position: absolute;
                right: 8px;
            }
        }
    }
}

.derivatives {
    min-width: 330px!important;

    .popover-body {
        .tooltip {
            font-family: 'Montserrat';
            width: 100%;

            &-title {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
            }

            &-total {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-weight: 700;
                margin-top: 20px;
                font-size: 14px;
                .green {
                    color: #1BA159;
                }

                .red {
                    color: #D11325 !important;
                }
            }

            &-content {
                margin: 1.5rem 0;

                .tooltip-bold {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 15px;
                    width: 79px;
                    height: 24px;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding: 0 8px;
                    color: white;

                    &.green-box {
                        background-color: #0F3730;

                    }

                    &.red-box {
                        background-color: #320C1F;
                }

                    &.tooltip-indent {
                        margin-top: 1.2rem;
                    }
                }

                .bold {
                    font-weight: 700;
                }

                .tooltip-red-border {
                    background-color: #320C1F;
                    padding: 0 5px;
                    color: white;
                }

                .tooltip-green-border {
                    background-color: #0F3730;
                    padding: 0 5px;
                    color: white;
                }

                .tooltip-green-border-bottom {
                    background-color: #0F3730;
                    padding: 0 5px;
                }

                .tooltip-red-border-bottom {
                    background-color: #320C1F;
                    padding: 0 5px;
                }
            }
        }

    }
}

.derivative-item-wrapper {

    &.color-scheme {
        &-DARK {
            color: $white0;

            td.bg-column {
                background-color: rgba(20, 20, 60, 0.3);
            }

            td:nth-child(n + 7):not(:nth-child(n + 10)) {
                background-color: rgba(20, 20, 60, 0.3);
            }

        }
        &-LIGHT {
            color: #0a0a1e;

            td.bg-column {
                background-color: rgba(20, 20, 60, 0.05);
            }

            td:nth-child(n + 7):not(:nth-child(n + 10)) {
                background-color: rgba(20, 20, 60, 0.05);
            }
        }
    }

    td {

        &.derivative-join-column {
            width: 100%;

            .derivative-expired {
                text-align: center;
            }
        }

    }
}
.derivatives-btn-tooltip {
    .tooltip{
        &-title {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
    
            &.green {
              color: #1BA159;
            }
    
            &.red {
              color: #D11325;
            }
        }

        &-content {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            white-space: nowrap;

  

            p {
                .green {
                    color: #38C97B;
                  }
          
                  .red {
                    color: #FF4758;
                  }
                  .tooltip-note {
                    font-size: 10px;
                    color: #999BBC;
                    padding-top: 10px;
                    line-height: 14px;
                  }
                margin: 0;
                &:first-child {
                    // padding-top: 0.7rem;
                    // border-top: 0.1px solid rgba(93, 95, 124, 0.3);
                    font-weight: 700;
                }
            }
        }
    }
}

.rfq-btn-tooltip {
    .tooltip{
        &-title {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 24px;
    
            &.green {
              color: #1BA159;
            }
    
            &.red {
              color: #D11325;
            }
        }

        &-content {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            white-space: nowrap;
            .tooltip-bold {
                font-weight: bold;
            }
            .green {
                color: #1BA159;
            }
        
            .red {
                color: #D11325;
            }
            p {
                margin: 0;
            }
        }
    }
}
@keyframes dash {
    0% {
        stroke-dashoffset: 47;
    }
    100% {
        stroke-dashoffset: 0;
    }
  }
  
  @keyframes circle {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg)
    }
  }

  @keyframes circle-path {
    0%,
    25% {
      stroke-dashoffset: 47;
      transform: rotate(0);
    }
  
    50%,
    75% {
      stroke-dashoffset: 13;
      transform: rotate(45deg);
    }
  
    100% {
      stroke-dashoffset: 47;
      transform: rotate(360deg);
    }
  }

.block-search {
    width: 100%;

    @media (max-width: 768px) {
        width: 580px;
    }

    .OpiumFieldset {
        &.color-scheme {
            &-DARK {
                .SearchBox-wrapper {
                    .OpiumInput {
                        color: $white0;
                    }
                }
            }
            &-LIGHT {
                .SearchBox-wrapper {
                    .OpiumInput {
                        color: $darkblue1;
                    }
                }
            }
        }
    }
}

.derivatives-animation-block {
    max-width: max-content;
    width: 100%;
    margin-left: 1.5rem;
    @media (max-width: 425px) {
        max-width: 100%;
    }

    .derivatives-tabs-anim-svg {
        float: right;
        position: relative;
        right: 0;
        top: 0;

        &.color-scheme {
            &-DARK {
                color: $white0;
            }
            &-LIGHT {
                color: $darkblue1;
            }
        }
    }
}

.explorer-filters-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 3rem;
    @media screen and (max-width: 425px) {
      flex-wrap: wrap;
    }
  
    .filter-wrapper {
      max-width: 160px;
      margin-right: 3rem;
      width: 100%;

      .SelectMultiple {
        &.color-scheme {
            &-DARK {
                color: $white0;
            }
            &-LIGHT {
                color: $darkblue1;
            }
        }
      }
  
      @media screen and (max-width: 425px) {
        margin: 0 auto 2rem 0;

        @media screen and (max-width: 360px) {
            max-width: 46%;

            @media screen and (max-width: 320px) {
                max-width: 100%;
            }
        }
      }
    }
  }

.derivatives-filters-wrapper {
    margin-bottom: 0;
    padding-right: 1.5rem;

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;

        @media (max-width: 425px) {
            padding-right: 5px;
        }
      }

    .limit-btn-wrapper {
        max-width: 165px;
        margin-right: 2.5rem;
        width: 100%;

        @media screen and (max-width: 768px) {
             margin-bottom: 2rem;

             @media screen and (max-width: 425px) {
                max-width: 100%;
                text-align: center;
                margin-right: 0;
           }
        }

        .limit-orders-btn {
            width: 165px;
            padding: 6px 0!important;
            display: flex;
            justify-content: flex-start;
            border-radius: 0;
            font-weight: 400;
            font-size: 16px;

            img {
                margin-top: -2px;
            }
        }
    }

    .filter-wrapper {
        @media (max-width: 768px) {
            margin-bottom: 2rem;
          &:nth-child(3) {
              margin-right: 0;
          }
        }

        &.filter-types {
            @media (max-width: 425px) {
                margin-right: 0;
              }
        }
    }
}

.explorer-filters-buttons {
    width: 100%;
    margin-bottom: 1.8rem;
    display: flex;
    flex-wrap: wrap;
  
    @media screen and (max-width: 425px) {
      margin-top: 2rem;
      justify-content: space-between;
    }
  
    .explorer-button {
      display: flex;
      flex-direction: row;
      margin-right: 20px;
      margin-top: 0.7rem;
  
      @media screen and (max-width: 375px) {
        margin: 0;
      }
  
      &:hover {
        .label {
          color: #999BBC;
        }
  
        .opiumBtn {
          svg {
            line {
              stroke: #999BBC;
            }
          }
        }
      }
  
      .label {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #FFFFFF;
        margin-right: 10px;
        text-transform: capitalize;
  
        @media screen and (max-width: 425px) {
          font-size: 12px;
          line-height: 12px;
        }
      }
  
      .opiumBtn {
        border: none;
        padding: 0;
  
        .oracles-remove-btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 6px 16px;
  
          position: relative;
          width: fit-content;
          height: 26px;
  
          background: #2C2C4A;
          border-radius: 100px;
          transition: all 0.15s linear;
        }
  
        svg {
          width: 9px;
          height: auto;
          margin: 0;
        }
      }
    }
  }

  .message {
    &.color-scheme {
        &-DARK {
            color: $white0;
        }
        &-LIGHT {
            color: $darkblue1;
        }
    }
  }
