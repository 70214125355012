@import '../../Constants/Styles/colors.scss';

.mobile-menu {
  display: none;
  flex: 1;
  align-items: center;

  &__toggler {
    margin: 0 10px;

    transition: transform .3s ease;

    svg {
      width: 22px;
      height: 12px;
    }
    &.active {
      transform: rotate(180deg);
    }
  }
}

.mini-logo-wrap {
  display: none;
  padding-right: 1rem;
  img {
    width: 40px;
    height: auto;
  }
}

.active-element {
  color: $white0;
  display: none;
  align-items: center;
  width: 100%;
  margin-right: 10px;

}

.menu {
  display: none;
  position: absolute;
  transform: translate(0, 0);
  top: 100%;
  left: auto;
  left: 0;
  padding: 20px 0 10px 0;
  width: 20%;
  border-radius: 10px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.35s ease;

  z-index: 1;

  overflow: auto;
  max-height: calc(100vh - 80px);

  ul {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    button {
      background-color: #e2dfdf;
      border: 0;
      outline: none;
      padding: 0 25px;
      font-size: 16px;
      line-height: 52px;
      font-weight: bold;
      color: #fff;

      margin-bottom: 20px;
    }
  }

  li {
    margin: 0 30px;
    display: block;

    &.active-block {
      display: block;
      justify-content: center;
      line-height: 52px;
      &::after {
        display: block;
        content: '';
        background: linear-gradient(90deg, #0A0A1E 2.92%, #2ECD94 12.48%, #FFF200 39.97%, #EA099F 66.93%, #197CD8 91.24%, #0A0A1E 96.24%);
        height: 1px;
        width: 100%;
        margin-left: -15px;
      }
    }

    a, .menu__link {
      font-size: 16px;
      line-height: 52px;
      font-weight: bold;
      color: #121212;
      text-decoration: none;
      display: flex;
      justify-content: left;

      transition: color 0.35s ease;
      cursor: pointer;

      img {
        margin-right: 10px;
        width: 24px;
      }

      &:hover {
        color: #e3000f;
      }
      &.wallet-address {
        position: relative;
        color: #e3000f;
        .info {
          position: absolute;
          top: 50%;
          right: -13px;
          transform: translateY(0);
          font-size: 10px;
          background-color: #fff;
          line-height: 10px;
          padding: 3px;
        }
      }
    }
  }

  &.active {
    opacity: 1;
    pointer-events: all;
  }
}

.mobile-content-btn {
  display: none;
}

.Governance__table-body {
  display: none;
}

@media (max-width: 768px), (max-width: 736px), (max-width: 1024px) and (orientation: landscape) {
  .mobile-menu {
    display: flex;
    span {
      width: calc(100% - 88px);
    }
  }

  .mini-logo-wrap {
    display: block;
  }

  .toggle-nav,
  .menu {
    display: block;
  }

  .menu {
    width: 90vw;
  }

  .active-element {
    display: flex;
    margin: 0;
  }

  .mobile-content-btn {
    display: block;
  }
  .Governance__table-body {
    display: block;
  }
  .mini-logo-wrap {
    padding-right: 0;
    margin-right: 32px;

    img {
      width: 57px;
    }

    @media screen and (max-width: 375px) {
      margin-right: 20px;
    }
  }
}

