@import '../../Constants/Styles/colors.scss';

$cell1: 8%;
$cell2: 10%;
$cell3: 28%;
$cell4: 18%;
$cell5: 18%;
$cell6: 14%;

.mining-table {
  * {
    word-break: break-word;
  }
  .table-head-cell {
    justify-content: flex-start;
  }
  .table-cell {
    justify-content: flex-start;
  }
  & .text-block {
    width: 100%;
    white-space: normal;
    word-break: break-all;
  }

  .image-title, .text-block-content p {
    img {
      margin-bottom: -3px;
    }
  }

  .cell {
     &:nth-child(1) {
      width: $cell1;
      justify-content: center;
     }
     &:nth-child(2) {
        width: $cell2;
     }
     &:nth-child(3) {
        width: $cell3;
     }
     &:nth-child(4) {
        width: $cell4;
     }
     &:last-child {
        width: $cell5;
        display: flex;
        text-align: right;
        justify-content: center;
        div {
           display: flex !important;
           align-items: center;
           justify-content: center;
           flex-wrap: wrap;
        }
     }
  }
  .table-body {
    .table-row {
      .cell {
        &:last-child {
          .btn {
            margin: 0;
            div {
              word-break: inherit;
            }
          }
        }
      }
    }
  }
  &.mobile {
     .table-head {
        display: none;
     }
  }
}

.collapse-block {
  margin-bottom: 16px;
}

.page-layout.mining {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100vh - 215px);
  padding-top: 14px;

  .on-chain {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
}

.mining-block {
  display: flex;
  align-items: center;
  min-height: 152px;
  padding: 16px 30px 16px 0;
  &__main-container {
    display: flex;
    align-items: center;
    margin-right: 33px;
    width: 32.147%;
  }
  &__datetime {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 47px;
    height: 100%;
    transform: rotate(270deg);
    margin-right: 6px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    span {
      white-space: nowrap;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-right: 25px;

    min-width: 100px;
    height: 72px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__description {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    height: 100%;

    .description {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      margin-top: 12px;
      border: 0;
      padding: 0;
      background-color: transparent;

      a {
        display: none;
      }
    }

    .text-block {
      font-size: 12px;
      br {
        content: ' ';
        display: block;
        margin: 10px 0;
      }
      a {
        display: inline-block;
      }
    }

    .color-scheme {
      &-DARK {
        a {
          color: $white0;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 6px;
            height: 6px;
            background-image: url('../../Images/icons/link-target-blank-white.svg');
            background-size: contain;
            background-position: center;
          }
          &:after {
            content: ' ';
            display: inline-block;
            width: 9px;
          }
        }
      }

      &-LIGHT {
        a {
          color: $darkblue1
        }
      }
    }


  }


  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;

    margin-bottom: 4px;
  }

  &__created-time {
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
  }

  &__total-size-block {
    margin-right: 33px;
    width: 17.681%;

    .child {
      .text-label {
        font-size: 12px;
        margin-bottom: 4px;
      }
      .text-content {
        font-size: 14px;
      }
    }

    .line {
      margin-bottom: 22px;
      .content-block {
        margin: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__reward-block {
    height: 100%;
    display: flex;
    justify-content: center;
    margin-right: 33px;
    width: 17.681%;

    .text-label {
      font-size: 12px;
      margin-bottom: 4px;
    }
    .text-content {
      font-size: 14px;
    }

    .description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
    &.mobile {
      display: none;
    }
  }

  &__action-btns {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    align-items: flex-end;

    .action-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      .btn {
        font-size: 14px;
      }

      &.centered {
        justify-content: center;
      }
    }

    button {
      margin-bottom: 1rem;
      min-width: 123px;
    }

    img {
      width: 16px;
      margin: auto;
    }

    .arrow-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 130px;
      height: 40px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.mining-content-block {
  display: flex;
  padding: 10px 30px 21px 30px;
  .content-items {
    margin-top: 28px;
    min-width: 305px;
    margin-right: 50px;
    .text-label {
      font-size: 12px;
    }
    .text-content {
      font-size: 14px;
    }
  }

  .no-data {
    min-height: 150px;
    width: 100%;
    display: flex;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1200px) {
  .mining-block {
    padding-right: 13px;
    &__reward-block {
      display: none;
      justify-content: flex-start;
      &.mobile {
        display: flex;
        &.row {
          display: none;
        }
      }
    }
    &__datetime {
      width: 39px;
      margin-right: 18px;
    }
    &__logo {
      min-width: 101px;
      height: 63px;
      margin-right: 18px;
    }
    &__title {
      margin-bottom: 5px;
    }
    &__reward-block {
      width: 100%;
    }
    &__total-size-block {
      margin-right: 0;
      width: 23.193%;
      .line {
        margin-bottom: 13px;
      }
    }
    &__main-container {
      width: 41.416%;
      margin-right: 19px;
    }
  }
  .mining-content-block {
    padding-top: 0;
    padding-left: 12px;
    .content-items {
      min-width: 315px;
      margin-right: 0;
    }
  }
}

@media (max-width: 768px) and (orientation: portrait)  {
  .mining-block {
    display: block;
    padding-top: 21px;
    padding-bottom: 9px;
    padding-right: 0;
    &__created-time {
      letter-spacing: 0.02rem;
    }
    &__datetime {
      display: block;
      width: 100%;
      transform: none;
      text-align: center;
      margin-bottom: 10px;
      br {
        content: '';
        &:after {
            content: ' ';
        }
      }
      span {
        display: inline-block;
      }
    }
    &__logo {
      width: 84px;
      height: 64px;
    }
    &__main-container {
      justify-content: space-between;
      padding-left: 38px;
      margin-bottom: 20px;
      width: 100%;
      margin-right: 0;
    }
    &__description {
      margin: 0;
      max-width: 51.5%;
      .description {
        margin-top: 10px;
      }
      .text-block {
        br {
          margin: 6px 0;
        }
        a {
          text-decoration: underline;
          &:before, &:after {
            display: none;
          }
        }
      }
    }
    &__total-size-block {
      margin-right: 0;
      width: 100%;
      .mining-block__reward-block.mobile {
        display: none;
      }
      .text-block-content {
        display: flex;
      }
      .child {
        display: block;
        width: 100%;
        .line {
          display: inline-block;
          width: 50%;
          padding: 0 15px;
          margin-bottom: 13px;
        }
        .text-label {
          margin-bottom: 2px;
        }
        & > .content-block {
          padding: 0 15px;
        }
      }
    }
    &__action-btns {
      align-items: center;
      .btn {
        width: calc(50% - 8px);
      }
      .action-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        padding: 0 15px;
        margin-bottom: 7px;
      }
      .arrow-btn {
        width: 100%;
        height: 36px;
      }
      button {
        margin: 0;
      }
    }
  }
  .mining-content-block {
    flex-direction: column;
    padding-bottom: 4px;
    .content-items {
      margin-top: 0;
      .child {
        display: block;
        width: 100%;
        .line {
          display: inline-flex;
          width: 50%;
          .content-block {
            flex-direction: column;
            padding: 0 5px;
            margin-bottom: 8px;
          }
        }
        .text-label {
          width: 100%;
        }
        .text-content {
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}

@media (max-width: 812px) and (orientation: landscape) {
  .mining-block {
    &__logo {
      margin-right: 47px;
    }
    &__reward-block.mobile {
      display: none;
    }
    &__main-container {
      margin-right: 24px;
    }
    &__action-btns {
      justify-content: stretch;
      .arrow-btn {
        width: 100%;
        padding-left: 20px;
      }
      .action-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        padding-right: 20px;
        margin-bottom: 15px;
        .btn {
          min-width: 148px;
          margin: 0;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    &__total-size-block {
      .child {
        margin-bottom: 20px;
        &:nth-child(even) {
          padding: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &__description {
      .description {
        margin-top: 16px;
      }
      .text-block {
        a {
          text-decoration: underline;
          &:after, &:before {
            display: none;
          }
        }
      }
    }
  }
  .mining-content-block {
    .content-items {
      width: 100%;
      margin-left: 180px;
      margin-top: 0;
      .child {
        display: inline-flex;
        width: 33%;
        flex-direction: column;
        padding: 0 5px;
        margin-bottom: 8px;
        .text-label {
          width: 100%;
          margin-bottom: 0;
        }
        .text-content {
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}
