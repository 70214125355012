@import '../../../../Constants/Styles/colors.scss';

.ExplorerPage__block-search {

  &.search-top {
    margin-top: 1.5rem;
  }
}
.ConstructorPage__search {
    width: 100%;
    position: relative;
  
    &-top {
      display: flex;
      position: relative;
      width: 100%;
      justify-content: space-between;
  
      @media (max-width: 425px) {
        flex-direction: column;
        margin-bottom: 1rem;
      }
  
      .popover__disabled-button {
        width: 70%;
      }
  
      .directives-back-link {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        margin-bottom: 12px;

        &.color-scheme-DARK {
            color: #fff;
          }
  
          &.color-scheme-LIGHT {
            color: #0C0C23;
          }
  
        img {
          margin-left: 1rem;
          cursor: pointer;
        }
  
        @media (max-width: 425px) { 
          padding-bottom: 15px;
        }
      }
    }
  
    .SearchBox__btn {
      border: none;
      padding: 5px;
    }
  
    @media (max-width: 768px) {
      padding:  0 3rem;
      width: 100%;
  
      @media (max-width: 425px) { 
        padding: 0 8px;
      }
    }
  
    &-title {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 90px;
      color: white;
    }
  
    .opium-link {
      margin-top: 1rem;
      position: relative;
      margin-right: 3rem;
      
      a {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #fff;
        text-decoration: none;
      }
    }
  }

.ExplorerPage {
  height: calc(100vh - 200px);
}

.ExplorerPage__left {

  @media (max-width: 768px) { 
    padding: 0 3rem;
  }

  @media (max-width: 425px) { 
    padding: 0 0.5rem;
  }

    .ConstructorForm__footer {
      width: 100%;

      .constructor-data-recieve {

        .constructor-bid-ask {
          padding: 1.5rem 1rem;
          display: flex;
          justify-content: space-between;

          @media (max-width: 393px) { 
            padding: 1.5rem 0;
          }


          @media (max-width: 320px) { 
            padding: 10px 0;
          }
        }
      }

    }

  }

.ExplorerPage__wrapper {
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) { 
    flex-direction: column;
  }

  #opium-tabs-component-tab-derivative-active {
    line-height: 24px;
    font-weight: 700;
    font-size: 16px;
  }

  .ConstructorForm__wrapper {
    border: 1px solid #5D5F7C;
    padding: 1.5rem 1rem;
    border-radius: 10px;
    width: 343px;
  
    @media (max-width: 1024px) { 
      width: 430px;
    }

    @media (max-width: 768px) { 
      width: 100%;

      @media (max-width: 320px) { 
        padding: 5px;
      }
    }
    .selectors-wrapper__divided {
      display: flex;
      justify-content: space-between;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;

      &.full-width {
        .select-with-label {
          width: 100%;
        }
      }

      @media (max-width: 320px) { 
        flex-direction: column;
      }
      .select-with-label {
        width: calc((100% - 1rem) / 2);

        @media (max-width: 320px) {
          width: 100%;
         }
      }

      .recieve-block {

        .explorer-address-wrapper {
          display: flex;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 1rem;

          &:last-child {
            margin-bottom: 0; 
          }

          .tokens {
            margin-left: 10px;
            font-weight: 400;
            font-size: 14px;
          }

          .color-scheme {
            &-DARK {
                color: $white0;
            }
            &-LIGHT {
                color: $darkblue1;
            }
        }

          .red-tokens {
            color: #D11325;
          }
          .green-tokens {
            color: #1BA159;
          }
        }
      }

      .divided-block-explorer {
        width: 100%;
        display: flex;
        flex-direction: column;

        .labels-wrapper {
          display: flex;
          padding: 0;
          align-items: center;
          font-size: 18px;
          .label-item {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 4px 8px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            font-family: "Montserrat", sans-serif;
            cursor: pointer;

            position: static;
            width: 70px;
            height: 24px;
            left: 0px;
            top: 0px;
            color: #fff;
            background: rgba(93, 95, 124, 0.15);
            border-radius: 100px;

            &.label-wide {
              width: 120px;
            }

            &.color-scheme-DARK {
              color: #fff;
            }
    
            &.color-scheme-LIGHT {
              color: #0C0C23;
            }

            .label-wrapper {
              display: flex;
              justify-content: center;

              &.label-right {
                margin-left: 5px;
              }

              img {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    .selectors-wrapper__row {
      @media (max-width: 393px) { 
        flex-direction: column;
        width: 100%;

        .select-with-label {
          width: 100%;
        }
      }
    }

    .selector-label {
        display: flex;
        align-items: center;

        &.color-scheme {
            &-DARK {
                color: $white0;
            }
            &-LIGHT {
                color: $darkblue1;
            }
        }

        img {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          margin-right: 8px;
          display: flex;
        }
      }
  
    .ticker {

      &-title {
        color: #999BBC;
        margin-bottom: 1rem;
        font-size: 14px;
        @media (max-width: 425px) { 
          font-size: 13px;
        }
      }
      &-text {
        font-size: 14px;
        font-weight: 500;

        @media (max-width: 360px) { 
          font-size: 14px;
        }

        @media (max-width: 320px) { 
          font-size: 13px;
        }

        &.color-scheme-DARK {
          color: #fff;
        }

        &.color-scheme-LIGHT {
          color: #0C0C23;
        }
      }
    }
  
  
    select {
      background: transparent;
      color: #fff;
      padding: 5px;
      border: none;
      border-bottom: 1px solid #5D5F7C;
      border-radius: 0;
      outline: none;
      width: 100%;
    }
  
    option {
      background: #0C0C23;
      color: #fff;
    }
  
    input[type="number"] {
      background: transparent;
      border: none;
      border-bottom: 1px solid #5D5F7C;
      color: #fff;
      padding: 5px;
      width: 100%;
    }
  }

  .ExplorerPage__right {
    padding: 0 3rem;
    width: 100%;

    @media (max-width: 1440px) { 
      padding: 0 0 0 3rem;
    }

    @media (max-width: 1024px) { 
      min-width: 450px;
      padding: 0 3rem;
    }

    @media (max-width: 768px) { 
      margin-top: 3rem;
      width: 100%;
    }

    @media (max-width: 425px) { 
      padding: 0 0.5rem;
      min-width: 385px;

      @media (max-width: 393px) { 
        min-width: 355px;

        @media (max-width: 390px) { 
          min-width: 350px;

          @media (max-width: 375px) { 
            min-width: 310px;

            @media (max-width: 320px) { 
              min-width: 280px;
            }
          }
        }
      }
    }

    .ConstructorData__wrapper {
      width: 100%;
      position: relative;
      .constructor-data-prices {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 36px;
        margin-bottom: 36px;

        @media (max-width: 768px) { 
          justify-content: space-between;
        }

        .constructor-data-prices-item {
          border: 1px solid #5D5F7C;
          padding: 1.5rem 1rem;
          border-radius: 10px;
          height: 6rem;
          width: 45%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          @media (max-width: 1024px) { 
              justify-content: space-between;
              padding: 0.5rem 1rem;

              @media (max-width: 320px) { 
                height: 7rem;
              }
            }

          &-title {
            color: #999BBC;
            margin-bottom: 5px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            @media (max-width: 375px) { 
              min-height: 1.8rem;
              display: flex;
              align-items: flex-start;
            }
          }
          &-text {
            color: #fff;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;

            @media (max-width: 360px) { 
              font-size: 16px;
              line-height: 18px;
            }

            &.color-scheme-DARK {
              color: #fff;
            }
  
            &.color-scheme-LIGHT {
              color: #0C0C23;
            }
          }
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-left: 3rem;

            @media (max-width: 375px) { 
              margin-left: 1rem;
            }
          }
        }
      }

      .constructor-payout-chart-tooltip {
        position: absolute;
        right: 0;
        top: 5px;
        z-index: 1000;
      }

      .derivative-hash {
        width: 45%;

        &.derivaive-hash-bottom {
          @media (max-width: 1024px) { 
            margin-bottom: 1rem;

            @media (max-width: 768px) { 
              margin-bottom: 0;

              @media (max-width: 425px) { 
                margin-bottom: 1rem;
              }
            }
          }
        }

        @media (max-width: 1440px) { 
          height: 80px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: inherit;

          @media (max-width: 1024px) { 
            width: 100%;
            display: block;
            @media (max-width: 768px) { 
              width: min-content;
              height: 80px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: inherit;

              @media (max-width: 425px) { 
                width: 100%;
                display: block;
              }
            }
          }
        }

        &__wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          @media (max-width: 1024px) { 
            flex-direction: column;

            @media (max-width: 768px) { 
              flex-direction: row;

              @media (max-width: 425px) { 
                flex-direction: column;
              }
            }
          }
        }
      
        &-title {
          margin-bottom: 0.5rem;
          color: #999BBC;
          font-size: 14px;

          @media (max-width: 425px) { 
            font-size: 13px;
          }
        }
  
        &-text {
          width: 24rem;
          // color: #fff;
          font-size: 14px;
          background-color: rgba(93, 95, 124, 0.15);
          padding: 4px 8px;
          border-radius: 100px;
          margin-right: 5px;
          display: flex;
          justify-content: center;
  
          @media (max-width: 1536px) { 
            width: 19rem;

            @media (max-width: 1024px) { 
            width: 16rem;

              @media (max-width: 360px) { 
                font-size: 14px;

                @media (max-width: 320px) { 
                  font-size: 13px;
                  margin-right: 0;
                }
              }
            }
          }
  
          &.light-bg {
            &.color-scheme-DARK {
              color: #fff;
            }
    
            &.color-scheme-LIGHT {
              color: #0C0C23;
            }
          }

          &.green-bg {
            color: #fff;
            background-color: #0D282A;
          }

          &.red-bg {
            color: #fff;
            background-color: #320C1F;
          }
        }
  
        &-content {
          display: flex;
          justify-content: flex-start;
          align-items: baseline;
          max-height: 2.5rem;
  
          img {
            cursor: pointer;
            width: 17px;
            margin-left: 8px;
          }
        }
  
        &-icon-wrap {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
        }
        &-link {
          cursor: pointer;
          display: flex;
          align-items: flex-start;
        }
      }
  
      .constructor-data-charts {
        &-title {
          border-bottom: 1px solid white;
          margin-bottom: 0 !important;
          display: flex;
          justify-content: space-between;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          width: 100%;
          color: white;

          img {
            margin-bottom: 4px;
            cursor: pointer;
          }
        }
        &-wrapper {
          width: 100%;
          padding: 0px;
          display: flex;
          justify-content: flex-start;
          left: 0;
          border: 1px solid #5D5F7C;
          padding: 1.5rem 1rem;
          border-radius: 10px;
          border-top-right-radius: 0;
          border-top-left-radius: 0;

          &.explorer {
              min-height: 245px;
              justify-content: center;
              align-items: center;
          }
          .color-scheme-DARK {
            color: white;
          }

          .color-scheme-LIGHT {
            color: #0C0C23;
          }
        }
        &-no-data {
          height: 256px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          
        }
      }

      .ExplorerPage__right {

        &-block {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .block-title {
            margin-bottom: 5px;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
    
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            width: 100%;

            &.color-scheme-DARK {
              color: #fff;
            }
  
            &.color-scheme-LIGHT {
              color: #0C0C23;
            }
          }
        }
      }

      .nav-tabs {
        .nav-link {
          margin-right: 1rem !important;
          padding: 0.5rem 1rem 0.5rem 0;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #999BBC;

          &.active {

            &.color-scheme-DARK {
                color: #fff;
              }
    
              &.color-scheme-LIGHT {
                color: #0C0C23;
              }
          }
        }
      }
    }

    .mint-link-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 2rem 0;

      .mint-btn {
        width: 213px;
        height: 24px;
        align-items: center;
        justify-content: center;
        display: flex;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;

        &.color-scheme-DARK {
            color: #fff;

            &:hover {
                color: #0C0C23;
            }
          }

          &.color-scheme-LIGHT {
            color: #0C0C23;

            &::after {
                content: '';
                background-image: url('../../../../Images/link-target-blank-black.svg');
            }

            &:hover {
                color: #fff;
                &::after {
                    background-image: url('../../../../Images/link-target-blank-white.svg');
                }
            }
          }

        &::after {
          content: '';
          position: relative;
          width: 6px;
          height: 6px;
          top: -3px;
          transition: background-image 0.15s linear;
        }
      }
    }
  }

  .constructor-block-title-DARK {
    width: auto;
    font-size: 16px;
    color: white;
    font-weight: 700;
    line-height: 24px;
    border-bottom: 1px solid white;
  }

  .constructor-block-title-LIGHT {
    width: auto;
    font-size: 16px;
    color: #0C0C23;
    font-weight: 700;
    line-height: 24px;
    border-bottom: 1px solid #0C0C23;
  }

  .constructor-form-bottom {
    margin-bottom: 40px;
  }

  .constructor-data-fee {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #5D5F7C;
    padding-bottom: 1.5rem;

    &-title {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      color: #999BBC;
    }

    &-text {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: baseline;
      height: 100%;
      display: flex;
      align-items: center;

      &.color-scheme-DARK {
        color: #fff;
      }

      &.color-scheme-LIGHT {
        color: #0C0C23;
      }

      .popover__disabled-button {
        display: flex!important;
      }
    }

    img {
      margin-left: 7px;
      cursor: pointer;
    }
  }

  .collateral-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: baseline;
    white-space: nowrap;

    &:last-child{
        margin-bottom: 0;
    }

    &.color-scheme-DARK {
        color: #fff;
      }

    &.color-scheme-LIGHT {
        color: #0C0C23;
      }

    @media (max-width: 425px) { 
      white-space: pre-line;
      @media (max-width: 320px) { 
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.ExplorerForm__footer {
    width: 100%;

    .constructor-data-recieve {

      .constructor-bid-ask {
        padding: 1.5rem 1rem;
        display: flex;
        justify-content: space-between;

        @media (max-width: 393px) { 
          padding: 1.5rem 0;
        }

        .bid-ask-items {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          min-width: 50%;

          .bid-ask-titles {
            display: flex;
            width: 100%;

            .bid-ask-item-title {
              width: 50%;

              &:last-child {
                margin-left: 1rem;

                @media (max-width: 1024px) { 
                  margin-left: 2.5rem;

                  @media (max-width: 768px) { 
                    justify-content: center;

                    @media (max-width: 425px) { 
                      margin-left: 2rem;
                      justify-content: flex-start;
                    }
                  }
                }
              }
            }
          }

          .bid-ask-content {
            display: flex;
            justify-content: space-between;

            .bid-ask-expired {
              display: flex;
              justify-content: center;
              width: 100%;

              &.color-scheme {
                font-size: 12px;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                line-height: 16px;

                &-DARK {
                  color: #FFFFFF;
                }
  
                &-LIGHT {
                  color: $darkblue1;
               }
              }
            }
          }

          @media (max-width: 1024px) { 
            width: 50%;
          }
        }

        .bid-ask-item {
          display: flex;
          flex-direction: column;

          &-title {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: flex-end;
            color: #999BBC;
            margin-bottom: 0.6rem;

            img {
              margin-left: 5px;
              cursor: pointer;
            }
          }
          &-size {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;

            @media (max-width: 320px) { 
              justify-content: flex-end;
            }

            &.color-scheme {
              &-DARK {
                color: #FFFFFF;
              }

              &-LIGHT {
                color: $darkblue1;
             }
            }

            .item-btn {
              width: 75px;
              padding: 0 0.3rem;
              border-radius: 100px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 400;
              white-space: nowrap;
              border: none!important;

              &.item-green {
                background: rgba(27, 162, 89, 0.2)!important;

                &.color-scheme {
                  &-LIGHT {
                      color: $darkblue1!important;
                  }

                  &-DARK {
                      color: #1BA259!important;
                  }
              }

                &:hover {
                    color: white!important;
                    background: #1BA259!important;
                }
              }

              &.item-red {
                background: rgba(209, 19, 37, 0.2)!important;

                &.color-scheme {
                  &-LIGHT {
                      color: $darkblue1!important;
                  }

                  &-DARK {
                      color: #D11325!important;
                  }
              }

                &:hover {
                    color: white!important;
                    background: #D11325!important;
                }
              }
            }
          }
        }

        @media (max-width: 320px) { 
          padding: 10px 0;
        }
      }
    }

  }

  .label-size {
      font-size: 12px;
  }

  .block-with-line {
    border-bottom: 1px solid #5D5F7C;
  }

  .block-with-top-line {
    border-top: 1px solid #5D5F7C;
  }
