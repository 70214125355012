@import '../../../Constants/Styles/colors.scss';
.ConstructorPage__left {

  @media (max-width: 1024px) and (orientation: landscape) {
    width: 400px;
  }

  @media (max-width: 768px) { 
    padding: 0 3rem;
  }

  @media (max-width: 425px) { 
    padding: 0 0.5rem;
  }

    .ConstructorForm__footer {
      border: 1px solid #5D5F7C;
      padding: 1.5rem 1rem;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      width: 450px;

      @media (max-width: 1024px) { 
        width: 400px;
      }

      @media (max-width: 768px) { 
        width: 100%;
      }

      .constructor-data-recieve {

        .constructor-data-fee {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 25px;

          &-title {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            color: #999BBC;
          }

          &-text-DARK {
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            color: #999BBC
          }

          &-text-LIGHT {
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            color: #999BBC;
          }

          img {
            margin-left: 7px;
            margin-bottom: -2px;
            cursor: pointer;
          }
        }




        &-item-title {
          color: #999BBC;
          margin-bottom: 5px;
          font-size: 12px;
        }
        &-item-text {
          width: 100%;
          color: #fff;
          font-size: 16px;
          display: flex;
          justify-content: space-around;
          .part-left {
            padding-right: 20px;
            width: 50%;
            .red-tokens {
              color: #F6029C
            }
            .green-tokens {
              color: #2ECD94
            }
            .positions-address-wrapper {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              padding: .5rem 0;
  
              // .color-scheme-DARK {
              //   color: #D11325
              // }
              // .color-scheme-LIGHT {
              //   color: #0C0C23;
              // }
            }
          }
          .part-right {
            width: 50%;
            .tokens-wrapper {
              display: flex;
              justify-content: flex-end;
              padding: 0.5rem 0;
              .color-scheme-DARK {
                color: white
              }
              .color-scheme-LIGHT {
                color: #0C0C23
              }
              .token-block {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 4px 8px;
  
                width: 130px;
                height: 24px;
                left: 188px;
                top: 120px;
  
                border-radius: 100px;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                margin-right: 10px;
              }

              .open-icon {
                cursor: pointer;
              }

              &:first-child {
                .token-block {
                  background-color: rgba(93, 95, 124, 0.15);
                  @media (max-width: 320px) { 
                    margin-bottom: 30px !important;
                  }
                }
              }

              &:last-child {
                .token-block {
                  background-color: rgba(93, 95, 124, 0.15);
                }
              }
            }
          }
        }

        .total-collateral-block {
          display: flex;
          justify-content: space-between;
          margin-top: 1.7rem;
          border-bottom: 0.5px solid #5D5F7C;
          padding-bottom: 15px;
          
          button {
            width: 100%;
            overflow: inherit;
          }
          .total-collateral {
            &-title {
              color: #999BBC;
              margin-bottom: 1rem;
              font-size: 13px;
            }
            &-text {
              color: #fff;
              font-size: 16px;
              font-weight: bold;
            }
            
            .text-DARK {
              color: #fff;
            }
            .text-LIGHT {
              color: #0C0C23;
            }
          }

          button {
            max-height: 32px;
            transition: all 0.15s ease-in-out !important;
          }

          button.hovered {
            &:hover {
              color: #197CD8 !important;
              background-color: transparent !important;
            }
          }

          .popover__disabled-button {
            width: 100%;
          }
        }

        .user-balance-block {
          position: relative;
          display: flex;
          justify-content: space-between;
          margin-top: 1rem;
          align-items: baseline;

          &-text {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            color: #999BBC;
          }

          &-amount {
            color: #fff;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;

            &.color-scheme {
              &-DARK {
                  color: $white0;
              }
      
              &-LIGHT {
                  color: $darkblue1;
              }
            }
          }
        }
      }

    }

  }

  #opium-tabs-component-tab-receive-active {
    line-height: 24px;
    font-weight: 700;
    font-size: 16px;
  }

  #opium-tabs-component-tab-put-to-amm {
    line-height: 24px;
    font-weight: 700;
    font-size: 16px;
  }
  
  .ConstructorForm__footer {
    border-top-right-radius: none !important;
    border-top-left-radius: none !important;
  }

  .opiumBtn {

      .button-label {
        display:flex;
        white-space: pre-line;
        justify-content: center;
        align-items: center;
        height: 1.5rem;
    
        .total-content {
          font-weight: 400;
        }

        svg {
          margin-left: 5px;
          margin-bottom: 2px;
          cursor: ponter;
          path {
            transition: all 0.15s linear;
          }
        }
      }

      &:hover {
        svg.label-icon-color {
          path {
            fill: #197CD8;
            color: #197CD8;
          }
        }
      }

      @media (max-width: 375px) { 
        padding: 6px !important;
      }

      @media (max-width: 360px) { 
        font-size: 11px !important;
      }
  }

.collateral {
  min-width: 330px;

  .popover-body {
    p {
      &:first-child {
        margin-bottom: 1rem;
      }

      .green {
        color: #2ECD94;
      }

      .red {
        color: #F6029C;
      }
    }
  }
}
