@import '../../Constants/Styles/constants.scss';
@import '../../Constants/Styles/colors.scss';

.networkSwitch,
.mobileNetworkSwitch {
    .__switchButton {
        background: transparent;
        border: none;

        & > img {
            width: 2rem;
            height: 2rem;
            margin-right: 10px;
        }
    }
}

.networkSwitch {
    &.color-scheme {
        &-DARK {
            color: $white0;
            button {
                color: $white0;
                display: flex;
                align-items: center;
            }
        }

        &-LIGHT {
            color: $darkblue1;
            button {
                color: $darkblue1;
                display: flex;
                align-items: center;
            }
        }
    }
}

.network__label {
    border: 1px solid $blue1;
    box-sizing: border-box;
    border-radius: 5px;
    color: $blue1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
    text-transform: uppercase;
    padding: 0.3rem 1.5rem;
    background-color: transparent;
    border-radius: 100px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    cursor: default;
    @media (max-width: 1024px) {
        margin-left: 1.5rem;
        @media (max-width: 940px) {
            display: none;
        }
    }
}

.protocol-version {
    max-width: 260px;

    &-tooltip {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }
}

.modal.login-popup {
    .modal-dialog {
        width: 100%;
        max-width: 342px;
    }
}

.popup-item-wrap {
    max-height: 235px;
    overflow: hidden;

    &.show_all {
        max-height: 235px;
        height: auto;
        overflow-y: auto;
    }
}

.popup-item {
    display: flex;
    flex-direction: row;
    align-content: center;
    border: 1px solid transparent;
    padding: 12px 15px;
    margin-bottom: 5px;
    position: relative;

    img {
        width: 2rem;
        height: 2rem;
        margin-right: 29px;
    }

    &.active {
        background: none;
        cursor: not-allowed;
        border-radius: 5px;
    }

    &:hover {
        background: none;
        cursor: pointer;
        border-radius: 5px;
    }

    &.color-scheme {
        &-DARK {
            &.active {
                border-color: $white0;
            }

            &:hover {
                border-color: $white0;
            }
        }

        &-LIGHT {
            &.active {
                border-color: $darkblue1;
            }

            &:hover {
                border-color: $darkblue1;
            }
        }
    }



    .network-name {
        display: flex;
        align-content: center;
        align-items: center;

        &.color-scheme {
            &-DARK {
                color: $white0;
            }
    
            &-LIGHT {
                color: $darkblue1;
            }
        }
    }

    &.network-switch-btn {
        width: 100%;
        justify-content: flex-start;
        border-radius: 5px!important;
    }

    &.cursor-not-allowed {
        cursor: not-allowed;
    }

    .popup-item-btn-content {
        display: flex;
        flex-direction: row;
        align-content: center;
        padding: 9px 5px;
        position: relative;
        color: white;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-size: 14px;
        font-weight: normal;
        font-weight: 400;
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .networkSwitch {
        display: none;
    }
}

@media (max-width: 1024px) and (orientation: landscape) {
    .networkSwitch {
        display: none;
    }
}

.network-new {
    top: 8px;
    left: 5px;
}

.move-circle {
    stroke-dasharray: 47;
    stroke-dashoffset: 0;
    animation: circle-path 2.5s linear infinite;
    transform-origin: 50% 50%;
}

.circle-svg {
    animation: 2s linear infinite both circle;
    position: absolute;
    right: 16px;
    top: 16px;

    &.wallet {
        top: 21px;
    }

    circle {
        position: absolute;
        right: 8px;
    }
}

@keyframes circle {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg)
    }
  }

  @keyframes circle-path {
    0%,
    25% {
      stroke-dashoffset: 47;
      transform: rotate(0);
    }
  
    50%,
    75% {
      stroke-dashoffset: 13;
      transform: rotate(45deg);
    }
  
    100% {
      stroke-dashoffset: 47;
      transform: rotate(360deg);
    }
  }
