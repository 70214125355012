@import '../../Constants/Styles/constants.scss';
@import '../../Constants/Styles/colors.scss';

.modal {
  .product-popup {
    .modal-dialog {
      width: 100%;
    }
  }

  .product-popup-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .widget-content {
      display: flex;
      width: 40%;
    }



    .graph-content {
      display: flex;
      flex-direction: column;
      width: 60%;
      nav {
        margin-left: 75px !important;
        a {
          font-size: 12px !important;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      justify-content: unset;
      align-items: center;

      .widget-content {
        width: 100%;
        margin-bottom: 2rem;
      }

      .graph-content {
        width: fit-content;
      }
    }
  }
}

.product {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 215px);
  width: 100%;

  overflow: auto;

  &__title {
    display: none;
    width: 100%;
    div {
      display: inline-block;
      width: 14px;
      height: 16px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 16px;
    }

    span {
      font-family: Montserrat, sans-serif;
      font-weight: 500;
      font-size: 22px;
      line-height: 20px;
    }
  }

  &__read-more {
    width: 100%;
    padding-top: 16px;
    margin-bottom: 30px;
    a {
      position: relative;
    }
    svg {
      position: absolute;
      top: 0;
      right: -10px;
    }

    &.color-scheme {
      &-DARK {
        a {
          color: $white0;
        }
      }
      &-LIGHT {
        a {
          color: $darkblue1;
        }
      }
    }
  }

  &-popup {
    .no-data {
      min-height: 256px;
      min-width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .close-button {
      top: 32px;
      right: 32px;
    }
    .widget-wrap {
      .widget-text {
        .alert {
          margin-bottom: 15px;
          @media (max-width: 480px) {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  & .hedging-alert {
    position: relative;
    text-align: center;
    border-radius: 10px;
    min-width: 400px;
    margin-bottom: 30px;

    & div {
      text-align: center;
    }
  }

  &__items {
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
            ". . .";
    width: 100%;
  }
  &__item {
    width: 265px;
    margin-bottom: 30px;
  }
  .item {

    width: calc(100% - 30px);
    max-width: 280px;

    height: 260px;
    border-radius: 10px;

    margin-bottom: 24px;

    &:not(.hovered) {
      transition: background-color .35s ease;
    }

    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: $white0;
      margin-bottom: 22px;
      br {
        // content: '';
        &:after {
          content: ' ';
        }
      }
    }
    &__image {
      position: relative;
      display: block;
      width: 82px;
      text-align: center;
      margin-bottom: 18px;
      img {
        height: 56px;
      }
    }
    &__network {
      width: 24px;
      height: 24px;
    }
    &__btn {
      display: flex;
      flex: 1;
      align-items: flex-end;
    }

    .title__container {
      display: flex;
      flex-direction: column;
    }

    &__description {
      flex-direction: row;
      align-content: space-between;
      justify-content: space-between;
    }
    &__description-more {
      flex-direction: column;
    }

    &__description,
    &__description-more {
      display: flex;

      width: 100%;

      margin-bottom: 16px;

      span {
        margin-bottom: 4px;

        &.item__subtitle {
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          margin-right: 10px;
        }

        &.item__date {
          font-weight: normal;
          font-size: 10px;
          line-height: 12px;
        }
      }

      .lines {
        .line {
          display: flex;
          justify-content: space-between;
          align-items: center;

          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          span {
            margin: 0;
          }

          &__title {
            font-weight: normal;
            font-size: 10px;
            line-height: 12px;
          }
          &__value {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
    }

    &__description-more {
      margin-bottom: 16px;
    }

    .BlockWithList__front, .BlockWithList__back {
      display: flex;
      flex-direction: column;
      align-items: center;

      padding: 23px 31px;
    }

    .BlockWithList__front {
      .item {
        &__description {
          span {
            &.item__subtitle {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              max-width: 190px;
            }
          }
        }
      }
    }

    .CollapseContainer__body,
    .BlockWithList__back {
      .item {
        &__title {
          margin-bottom: 17px;
          br {
            // content: '';
            &:after {
              content: ' ';
            }
          }
        }
        &__description {
          margin-bottom: 14px;
          &-more {
            margin-bottom: 13px;
            .lines {
              .line {
                margin-bottom: 8px;
              }
            }
          }
        }
      }
    }

    .btn {
      border-width: 1px;
      padding: 4px 16px;

      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-transform: lowercase;
    }

    &.color-scheme {
      &-DARK {
        .BlockWithList {
          &__front {
            .item {
              &__description {
                color: $white0;
                span {
                  color: $white0;
                }
              }

              &__description-more {
                color: $white0;
              }
            }
          }
        }
      }
      &-LIGHT {
        .BlockWithList {
          &__front {
            .item {
              &__description {
                color: $darkblue1;
                span {
                  color: $darkblue1;
                }
              }

              &__description-more {
                color: $darkblue1;
              }
            }
          }
        }
      }
    }

    &.fake-product {
      border: 1px solid transparent;
      transition: border-color .5s ease;

      &.color-scheme {
        &-DARK {
          &:hover {
            border-color: $white0;
          }
        }
  
        &-LIGHT {
          &:hover {
            border-color: $darkblue1;
          }
        }
      }
    }

  }

  .FilterControls {
    width: 100%;
    padding: 0 32px;
    margin-bottom: 30px;
  }
}

@media (max-width: 1200px) {

  .product {
    margin-left: -18px;
    width: calc(100% + 18px);
    padding-top: 6px;
    &__title {
      display: block;
      margin-bottom: 25px;
    }
    .FilterControls {
      padding: 0;
    }

    &__items {
      display: flex;
      flex-direction: row;
      & > div {
        width: 50%;
        &:nth-child(1) {
          .CollapseContainer {
            margin-right: 8px;
          }
        }
        &:nth-child(2) {
          .CollapseContainer {
            margin-left: 8px;
          }
        }
      }
    }

    .item {
      max-width: none;
      width: 100%;
      margin: 0;
      &__image {
        img {
          max-width: 93px;
          height: auto;
          max-height: 63px;
        }
      }
      &__btn {
        white-space: nowrap;
      }
      &__container-with-icon {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .item__network {
          display: block;
          width: 27px;
          height: 24px;
          img {
            display: block;
            height: 100%;
            width: auto;
          }
        }
      }
      &__container-with-icon-inner {
        display: flex;
        flex-direction: column;
      }
    }

    .CollapseContainer__header {
      .item {
        &__wrapper {
          display: flex;
          align-items: center;
          width: 100%;
        }
        &__title {
          width: 100%;
          margin-bottom: 18px;
        }
        &__image {
          display: block;
          float: left;
          margin: 0;
          width: 93px;
          margin-right: 47px;
        }
        &__btn {
          margin-top: 12px;
        }
        &__description {
          display: inline-flex;
          vertical-align: top;
          flex-direction: column;
          width: calc(100% - 145px);
          margin: 0;

          span {
            &.item__subtitle {
              white-space: pre-wrap;
              text-overflow: initial;
              overflow: visible;
              max-width: none;
            }
          }
        }
        .btn {
          display: inline-block;
          width: 100%;
        }
      }
    }

    .CollapseContainer {
      width: calc(100% - 8px);
      margin-bottom: 8px;
      &__body {
        padding-top: 21px;
        .item {
          &__description {
            margin-bottom: 0;
          }
        }
        .lines {
          .line {
            display: inline-block;
            width: 50%;
            margin-bottom: 8px;
            &__title {
              display: block;

              font-family: Montserrat, sans-serif;
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;

              width: 100%;
            }
            &__value {
              display: block;

              font-family: Montserrat, sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;

              width: 100%;
            }
          }
        }
      }
      &__container {
        padding: 22px 16px;
        padding-bottom: 4px;
      }
      &__collapse-btn {
        width: 100%;
        text-align: center;
        button {
          padding: 20px 35px;
          transform: rotate(180deg);
          transition: transform .3s ease;
        }
        &.opened {
          button {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .product {
    margin-left: 0;
    width: 100%;
    padding-top: 6px;
  }
}

@media (max-width: 768px) and (orientation: portrait) {
  .product {
    width: 100%;
    margin-left: 0;
    padding-top: 0;
    &__title {
      display: none;
    }

    .FilterControls {
      margin-bottom: 20px;
    }

    .CollapseContainer {
      margin-bottom: 16px;
      width: 100%;
    }

    &__items {
      flex-direction: column;
      & > div {
        width: 70%
      }
    }

    .item {
      height: 177px;
      .BlockWithList__front,
      .CollapseContainer__body {
        display: block;
        flex-direction: initial;
        align-items: initial;
        padding: 20px 20px 20px 35px;
      }

      .CollapseContainer__body {
        display: flex;
        flex-direction: column;
        .item {
          &__btn {
            justify-content: center;
          }
        }
      }
    }

    .hedging-alert {
      min-width: auto;
    }
    &-popup {
      .modal-dialog {
        min-width: unset;
        .modal-body .widget-wrap {
          border-radius: 5rem;
        }
      }
    }
    .hedging-alert {
      text-align: center;
      border-radius: 10px;

      div {
        text-align: center;
      }
    }
  }
}

@media (max-width: 812px) and (max-height: 425px) and (orientation: landscape) {
  .product {
    width: 100%;
    margin-left: 0;
    height: auto;
    &__title {
      display: none;
    }
    &__items {
      gap: 16px 16px;
    }
    .item {
      height: 250px;
      .BlockWithList__front,
      .CollapseContainer__body {
        display: block;
        flex-direction: initial;
        align-items: initial;
        padding: 20px;
      }
      &__wrapper {
        display: flex;
        align-items: center;
      }
      &__title {
        width: 100%;
        margin-bottom: 18px;
      }
      &__image {
        display: block;
        margin: 0;
        width: 50%;
        margin-right: 0;
        img {
          max-width: 93px;
        }
      }
      &__description {
        display: inline-flex;
        vertical-align: top;
        flex-direction: column;
        width: 50%;
        margin: 0;

        .item {
          &__date {
            margin-bottom: 12px;
          }
        }
      }
      .btn {
        display: inline-block;
        width: 100%;
      }
    }
  }
}

@media (max-width: 425px) {
  .product {
    height: calc(100vh - 275px);
    &__items {
      & > div {
        width: 100%
      }
    }
  }
}
