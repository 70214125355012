body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0A0A1E !important;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.false {
  height: 100%;
  min-height: inherit;
}

.main-layout {
  min-height: inherit;
  padding-top: 100px;

  @media (max-width: 1024px) and (orientation: landscape) {
    padding-top: 65px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 80px;
  }
}
