.home {
  &__wrapper {
    position: relative;
    overflow: auto;
    height: calc(100vh - 160px);
    width: 100%;
    min-height: 0;
    margin-top: 25px;

    @media (max-width: 768px) {
      margin-top: 8px;

      @media (max-width: 425px) {
        height: calc(100vh - 210px);
      }
    }

    &.color-scheme {
      &-DARK {
        .box-footer-text {
          color: #fff;
        }
        .box-banner-heading {
          color: #fff;
        }
      }
    }
    .box-banner-heading {
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      margin-top: 40px;
      @media (max-width: 768px) {
        margin-top: 50px;
        margin-bottom: 20px;
      }
    }
    .box-footer-wrapper {
      .box-footer-text {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        width: 70%;
        a {
          color: #ffffff;
          text-decoration: underline;
        }
      }
      @media (max-width: 768px) {
        width: 100%;
        margin: 20px 0 40px 0;

        @media (max-width: 425px) {
          width: 100%;
          margin: 20px 0 0 0;
        }
      }
    }
  }
}

.cards-wrapper {
  .custom-card {
    .card-warap {
      & > * {
        width: 100%;
      }
    }
  }
  height: fit-content;
}
.custom-slider-box.slide-to-left .slick-track{
  margin: 0;
}

.box-banner .slick-list {
  padding: 0 5% 0 0 !important;
}

.box-wrapper {
  max-width: 30rem;
  outline: none;
}

.pointer {
  cursor: pointer;
}

.turbo-aave-svg-text {
  font-size: 210%;
  font-weight: 500;
}

.turbo-aave-svg-text-mobile {
  font-weight: 500;
}

.turbo-matic-svg-text-mobile {
  font-weight: 500;
  font-size: 170%;
}

.turbo-eth-svg-text-mobile {
  font-weight: 500;
  font-size: 170%;
}

.turbo-eth-svg-apr-mobile {
  font-size: 90%;
}

$svg-color-green: #2ECD94;
$svg-color-black: #0A0A1E;
$svg-color-white: #ffffff;

.svg-stake-text {
  fill: $svg-color-black;
}

.svg-stake-bgc {
  fill: $svg-color-green;
}

.svg-stake-text-active {
  fill: $svg-color-green;
}

.svg-stake-bgc-active {
  fill: transparent;
}

.svg-find-text {
  fill: $svg-color-white;
}

.svg-find-bgc {
  fill: transparent;
}

.svg-find-text-active {
  fill: $svg-color-black;
}

.svg-find-bgc-active {
  fill: $svg-color-white;
}

.opium-research-slide {
  .try-opium-research-button {
    cursor: pointer;

    &_active {
      fill: #161633;
    }
  }

  .try-opium-research-button-text {
    cursor: pointer;

    &_active {
      fill: #197CD8;
    }
  }
}

.get-nft-slide {
  .get-nft-button {
    cursor: pointer;

    &_active {
      fill-opacity: 0;
    }
  }

  .get-nft-button-text {
    cursor: pointer;

    &_active {
      fill: #197CD8;
    }
  }
}

.opium-research-popup {
  &__button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.slide-mobile {
  @media screen and (max-width: 460px) {
    width: 100%;
    height: unset;
  }
}
