.ProductsPage__wrapper {
  margin-top: 15px;
  height: calc(100vh - 160px);

  @media (max-width: 1200px) {
    margin-top: 20px;

    @media (max-width: 768px) {
      margin-top: 0;

      @media (max-width: 425px) {
        height: calc(100vh - 200px);
      }
    }
  }

    .products-tabs-wrapper {
        padding: 2rem 0;
        .experimental-text {

          &.color-scheme-DARK {
            color: white;
          }
    
          &.color-scheme-LIGHT {
            color: #0A0A1E;
          }
          
            :first-child {
              font-weight: 600;
              font-size: 14px;
              margin-bottom: 5px;

              @media (max-width: 768px) {
                padding-top: 0.5rem;
              }
            }
            :last-child {
              width: 50%;
              font-size: 12px;
              line-height: 14px;

              @media (max-width: 768px) {
                width: 100%;
              }
            }

          }
    }
}

    #opium-tabs-component-tab-active-products, #opium-tabs-component-tab-turbo-products,
    #opium-tabs-component-tab-insurance-stakings, #opium-tabs-component-tab-opium-products-stakings {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        @media (max-width: 320px) {
            margin: 0;
          }
    }
    