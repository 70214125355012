@import '../../../../Constants/Styles/colors.scss';

.limit-orders-component {
    min-height: 360px;
    position: relative;
}

.limit-orders {
    height: 305px;
    @media (max-width: 768px) {
        overflow: auto;
    } 
}

.cancel-all-button {
    padding-top: 10px;
    position: relative;

    .orders-btn {
        background-color: transparent!important;

        &.color-scheme {
            &-DARK {
                &:hover {
                    background-color: white!important;
                }
            }
            &-LIGHT {
                &:hover {
                    background-color: $darkblue1!important;
                }
            }
        }
    }
}

.cancel-all-popup {
    z-index: 100000; 
}

.limit-orders-popup {
    z-index: 99999;
    .modal-dialog {
        max-width: 1075px!important;

        @media (max-width: 1024px) {
            max-width: 960px!important;

            @media (max-width: 768px) {
                max-width: 720px!important;
            }
        }

        .modal-content {
            height: 515px;
        }

        .modal-title {
            font-weight: 700;
            font-size: 22px;
        }
    }
}
.limit-orders-table {

    &.color-scheme {
        &-DARK {
            thead {
                background-color: #0A0A1E;
            }
        }
        &-LIGHT {
            thead {
                background-color: #fff;;
            }
        }
    }

    thead {
        position: sticky;
        top: 0;

        th {
            box-shadow: inset 0 -3.5px 1px -2px #34344d;
            width: 36%!important;
            padding: 10px 7px!important;

            &:first-child {
                width: 7%!important;
            }

            &:nth-child(4) {
                width: 14%!important;
                @media (max-width: 1024px) {
                    white-space: nowrap;
                }
            }

            &:nth-child(5) {
                width: 10%!important;
                padding-right: 2rem;
            }
        }
    }

    .limit-orders-wrapper {
        td {
            padding: 10px 7px!important;
            vertical-align: middle;

            .limit-orders-icon {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }
        }
    }

    .limit-orders-pay {
        display: flex;

        .pay-item-icon {
            height: 24px;
            cursor: pointer;
            border-radius: 50%;
        }

        .pay-content {
            display: flex;
            flex-direction: column;

            font-family: 'Montserrat';
            font-style: normal;
            font-size: 12px;
            line-height: 16px;

            align-items: baseline;
            
            .total {
                font-weight: 700;

                &.color-scheme {
                    &-DARK {
                        color: $white0;
                    }
                    &-LIGHT {
                        color: $darkblue1;
                    }
                }
            }

            .ticker {
                font-weight: 400;
                white-space: nowrap;

                &.color-scheme {
                    &-DARK {
                        color: #999BBC;
                    }
                    &-LIGHT {
                        color: $darkblue1;
                    }
                }

                .accent-text {
                    font-weight: 700;

                    &.LONG_POSITION {
                        color: #1BA259;
                    }
                    &.SHORT_POSITION {
                        color: #D11325;
                    }
                }
            }
        }
    }


    .limit-orders-timing {
        font-size: 12px;

        &.color-scheme {
            &-DARK {
                color: $white0;
            }
            &-LIGHT {
                color: $darkblue1;
            }
        }

        .timing-row {
          display: flex;
          white-space: nowrap;

           .timing-hour {
             color: #999BBC;
           }
        }
    }

    .limit-orders-status {
        display: flex;
        font-size: 12px;
        font-weight: 700;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .ACTIVE {
            color: #1BA159;
        }

        .EXPIRED {
            color: #D6A72E;
        }

        .CANCELED {
            color: #FF263A;
        }

        .FILLED {
            color: #999BBC;
        }

        .INVALID {
            color: #197CD8;
        }
    }

    .progress-active {
        cursor: pointer;
    }
}

.limit-orders-no-data {
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 290px;
    justify-content: center;
}

.limit-orders-tooltip {
    z-index: 100002;
}

.info-limit-orders-popup {
    z-index: 100000;
}

.status-progress {

    .progress-bar-title {
        
        .progress-value {
            width: 70%;
        }
    }
}

.cancel-limit-orders-popup {
    z-index: 100000;

    .cancel-limit-orders {
        font-family: 'Montserrat';
        font-style: normal;

        .orders-title {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;

            &.color-scheme {
                &-DARK {
                    color: $white0;
                }
                &-LIGHT {
                    color: $darkblue1;
                }
            }
        }

        .orders-text {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 3rem;

            &.color-scheme {
                &-DARK {
                    color: $white0;

                    a {
                        color: $white0;
                    }
                }
                &-LIGHT {
                    color: $darkblue1;

                    a {
                        color: $darkblue1;  
                    }
                }
            }

            &.text-line-height {
                line-height: 18px;
            }
        }

        .orders-actions {
            display: flex;
            justify-content: space-between;

            .orders-btn {
                min-width: 124px;
                background-color: transparent;

                &.btn-red {
                    color: #FF263A;
                    border-color: #FF263A;
                }
            }
        }
    }
}