.nft-verification {
  .nft-content {
    .opium-link a {
      color: #FFFFFF;
    }
  
    .action-buttons-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      @media screen and (max-width: 425px) {
        justify-content: space-between;
      }
  
      .opiumBtn {
        padding: 6px 40px;

        &:nth-child(1) {
          margin-right: 30px;
        }

        @media screen and (max-width: 425px) {
          &:nth-child(1) {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
