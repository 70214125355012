@import '../../Constants/Styles/constants.scss';
@import '../../Constants/Styles/colors.scss';

.header-wrapper {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  .fixed-header {
    &-DARK {
      background-color: #0A0A1E;
      // background: radial-gradient(72.74% 182.65% at 85.89% 66.02%, #111132 2.27%, #0A0A1E 98.05%);
    }

    &-LIGHT {
      // background: radial-gradient(100% 244.84% at 100% 56.35%, #DEDEFE 0%, #FFFFFF 63.43%);
      background-color: #F6F6FF;
      
    }
  }

  &__message {
    padding: 5px 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 2rem;

    color: white;
    div {
      display: inline-block;
      a {
        color: white;
        text-decoration: underline;
      }
    }
  }

  .wallet-address {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    padding-bottom: 1px;
    &:hover {
      border-radius: 8px;
      cursor: pointer;
    }

    &.color-scheme {
      &-DARK {
        &:hover {
          background: linear-gradient(90deg, #0A0A1E 2.92%, #2ECD94 12.48%, #FFF200 39.97%, #EA099F 66.93%, #197CD8 91.24%, #0A0A1E 96.24%);
        }

        &.active {
          &::after {
            display: block;
            content: '';
            background: linear-gradient(90deg, #0A0A1E 2.92%, #2ECD94 12.48%, #FFF200 39.97%, #EA099F 66.93%, #197CD8 91.24%, #0A0A1E 96.24%);
            height: 1px;
            width: 100%;
          }


        }


        button {
          // background-color: $darkgray1;
          background-color: #0A0A1E;
          color: $white0;
        }
      }

      &-LIGHT {

        &.active {
          &::after {
            display: block;
            content: '';
            background: linear-gradient(90deg, #FFFFFF 2.92%, #2ECD94 12.48%, #FFF200 39.97%, #EA099F 66.93%, #197CD8 91.24%, #FFFFFF 96.24%);
            height: 1px;
            width: 100%;
          }
        }

        &:hover {
          background: linear-gradient(90deg, #FFFFFF 2.92%, #2ECD94 12.48%, #FFF200 39.97%, #EA099F 66.93%, #197CD8 91.24%, #FFFFFF 96.24%);
        }

        button {
          // background: #ffffff;
          background-color: #F6F6FF;
          color: $darkgray1;
        }
      }
    }



    button {
      border: 0;
      display: flex;
      flex-direction: row;
      height: 100%;
      align-items: center;
      padding: 0 20px;
    }

    img {
      display: block;
      margin-right: 16px;
      width: auto;
    }
  }
  .disabled-wallet {
    background: none !important;
    opacity: .3 !important;
    cursor: unset !important;
  }
}

.header {
  height: indent(10);
  display: flex;
  align-items: center;
  position: relative;
  .nav-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    .logo-wrap {
      margin-right: 52px;
      img {
        width: 136px;
        height: auto;
        vertical-align: middle;
      }
    }
    .navigation {
      position: relative;
      display: flex;
      flex-direction: row;
      height: 32px;
      padding-bottom: 1px;

      &.color-scheme {
        &-DARK {
          &:hover {
            background: linear-gradient(90deg, #0A0A1E 2.92%, #2ECD94 12.48%, #FFF200 39.97%, #EA099F 66.93%, #197CD8 91.24%, #0A0A1E 96.24%);
          }

          &.active {
            background: linear-gradient(90deg, #0A0A1E 2.92%, #2ECD94 12.48%, #FFF200 39.97%, #EA099F 66.93%, #197CD8 91.24%, #0A0A1E 96.24%);
          }
        }
        &-LIGHT {
          &:hover {
            background: linear-gradient(90deg, #FFFFFF 2.92%, #2ECD94 12.48%, #FFF200 39.97%, #EA099F 66.93%, #197CD8 91.24%, #FFFFFF 96.24%);

          }

          &.active {
            background: linear-gradient(90deg, #FFFFFF 2.92%, #2ECD94 12.48%, #FFF200 39.97%, #EA099F 66.93%, #197CD8 91.24%, #FFFFFF 96.24%);
          }
        }
      }

      .navigation-item {
        position: relative;
        padding: 0 1rem;
        text-decoration: none;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 7px;

        &.mobile {
          display: none;
        }

        &:last-child {
          margin-right: 0;
        }

        &.color-scheme {
          &-DARK {
              // background-color: $darkgray1;
              background-color: #0A0A1E;
              color: $white0;
          }
          &-LIGHT {
              // background-color: $white0;
              background-color: #F6F6FF;
              color: $darkgray1;
          }
        }

        img {
          display: block;
          margin-right: 16px;
        }

        a {
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: white;
          padding: 12px 0 14px 32px;
          text-decoration: none;
          cursor: pointer;


        }
      }
      .navigation-item-active {
        border-radius: 8px;
      }
      div.navigation-item {
        &:hover {
          cursor: pointer;
        }
        .navigation-item {
          &__new {
            position: absolute;
            top: -5px;
            right: -19px;
            padding: 2px 7px;
            background: #197CD8;
            border-radius: 6px;
            text-transform: none;
            font-size: 12px;

            pointer-events: none;
            user-select: none;
          }
        }
      }
      .linkDisabled {
        cursor: not-allowed;
        opacity: 0.5;
        text-decoration: none;
      }
    }

    & .hedging-mining-staking {
      & .dropdown-menu {
        max-width: 8rem;
        min-width: 8rem;
      }
      & .dropdown-item {
        font-size: 14px;
        padding: 0.5rem 0 0.5rem 0.5rem;
      }
    }
  }

  .action-wrapper {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .lang {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: color(white);
      background-color: color(black);
      cursor: pointer;
    }
    .action-item {
      cursor: pointer;
    }
  }
  .link-wrap {
    width: 50%;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    .link-item {
      padding-right: 20px;
      cursor: pointer;
      a {
        &:hover {
          color: color(pureRed);
          text-decoration: none;
        }
      }
      &:first-of-type {
        padding-left: 20px;
      }
    }
  }
  .dropdown-item {
    padding: 0;
    a {
      width: 100%;
      display: block;
      padding: 0.25rem 1.5rem;
    }
  }

  &-wrapper {
    &.color-scheme {
      &-DARK {
        .navigation-item {
          color: color(white);
          &:hover {
            background-color: rgba(#f2f2f2, .2);
          }
          &-active {
            background-color: rgba(242, 242, 242, 0.2);
          }
        }
      }
      &-LIGHT {
        .navigation-item {
          color: color(black2);
          &:hover {
            background-color: rgba(color(blue1), .2);
          }
          &-active {
            background-color: rgba(color(blue1), .2);
          }
        }
      }
    }
  }
}

.header-pop-out {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-width: 110px;
  min-height: 80px;
  padding: 18px 12px 10px 12px;
  .action-item {
    width: 100%;
    color: white;
    margin-bottom: 5px;
    transition: color .3s ease;
    &:hover {
      color: rgba(white, .7);
    }
  }
}

.wallet-address {
  &.mobile-address {
    display: none;
  }
}

.logInInfo-popup {
  &__image {
    display: inline-block;
    vertical-align: top;
    margin-right: 30px;

    max-width: 32px;

    img {
      max-width: 100%;
    }
  }
  &__info {
    display: inline-block;
    vertical-align: top;

    width: calc(100% - 32px - 30px);
    margin-bottom: 40px;

    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;

      margin: 0 !important;
    }
  }
  &__bottom {
    margin-top: 17px;

    a {
      display: inline-flex;
      align-items: center;

      font-weight: 500;
      font-size: 9px;
      line-height: 11px;

      opacity: .5;

      svg {
        margin-right: 5px;
      }


      &:first-child {
        margin-right: 13px;
      }
    }

    &.color-scheme {
      &-DARK {
        a {
          color: $white0;
        }
      }
      &-LIGHT {
        a {
          color: $darkblue1;
        }
      }
    }
  }
  &__action-btns {
    display: flex;
    justify-content: space-between;
    button {
      width: 50%;
      max-width: 123px;
    }

    .opiumBtn {
      &.color-scheme {
        &-LIGHT {
          background-color: $white0!important;
          &:hover {
            background-color: #212529!important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  //.logo-wrap {
  //  display: none;
  //}
  //
  //.header .action-wrapper .wallet-address {
  //  display: none;
  //}
  //
  //.header .nav-wrapper .navigation {
  //  display: none;
  //}
}

@media screen and (max-width: 1024px) {


  .fixed-header {
    width: 100%;
    height: 80px;
  }

  .wallet-address {
    &.mobile-address {
      display: flex;
    }
  }
}

@media screen and (max-width: 820px) {
  .wallet-address {
    button {
      padding: 0 10px!important;
    }
  }
}

@media screen and (max-width: 768px), (max-width: 1024px) and (orientation: landscape) {

  .header-wrapper {
    &__message {
      display: none;
    }
  }

  .logo-wrap {
    display: none;
  }

  .header {
    .action-wrapper {
      position: relative;
      .wallet-address {
        display: none;
      }
    }

  }

  .header .nav-wrapper .navigation {
    display: none;
  }

  .walletConnect {
    display: none;
  }
  .header-wrapper .page-container {
    padding: 0 16px;
  }

  .wallet-address {
    &.desktop-address {
      display: none;
    }
  }
}

@media screen and (max-width: 520px) {
  .header {
    width: 100%;
    justify-content: space-between;
    &:before {
      display: none;
    }
  }
}

@media screen and (max-width: 480px) {
  .header {
    top: 0;
    left: 0;
    z-index: 1;
    .nav-wrapper {
      // padding: 0 5vw;
      justify-content: space-between;
    }
    .action-wrapper {
      position: relative;
      .overlay-trigger {
        position: relative;
        padding-bottom: 36px;
        top: 18px;
      }
    }

    & .nav-wrapper .navigation .navigation-item {
      padding: 0 0.5rem;
    }

    .wallet-address {
      &.desktop-address {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .header {
    & .nav-wrapper .navigation .navigation-item {
      font-size: 12px;
    }
  }
}

.nft-gift-button {
  width: 60px;
  height: 60px;
  margin: 0 20px 0 10px;
  padding: 0;
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 100;

  .nft-gift-button-bg {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    filter: blur(10px);

    .nft-gift-bg-block-red {
      background-color: #EA099F;
    }
    .nft-gift-bg-block-yellow {
      background-color: #FFF200;
    }
    .nft-gift-bg-block-blue {
      background-color: #197CD8;
    }
    .nft-gift-bg-block-green {
      background-color: #2ECD94;
    }
  }

  .nft-gift-button-image-wrapper {
    width: 100%;
    height: 100%;
    background: transparent;
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    .nft-gift-button-image-avatar {
      width: 10rem;
      height: 10rem;
      object-position: -3.1rem -.7rem;
      object-fit: cover !important;
    }

    .nft-gift-button-image-present {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }

    @media screen and (max-width: 768px) {
      border-radius: 8px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 45px;
    height: 45px;
  }

  @media screen and (max-width: 375px) {
    width: 35px;
    height: 35px;
    margin: 0 10px;
  }
}

.blacklist-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal.BlacklistedPopUp {
    .modal-dialog {
        width: 100%;
        max-width: 342px;
    }
}
