@import '../../../../Constants/Styles/colors.scss';

.order-review {
    .modal-header {
        margin-bottom: 1.5rem!important;
    }

    .modal-title {
        font-size: 14px!important;
        text-align: center;
    }

    &-popup {
        display: flex;
        flex-direction: column;
        position: relative;
        color: #fff;

        .head-text {
            width: 89px;
            font-weight: 700;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            font-size: 12px;
            line-height: 24px;

            &.SELL {
                background-color: #460D20;
            }

            &.BUY {
                background-color: #0E2E2C;
            }

            &.summary {
                background-color: #17172C;
            }
        }

        .content-text {
            display: inline-block;
            white-space: pre-line;
            font-size: 12px;
            width: 100%;
            padding: 6px 10px;
            
            p {
                text-transform: capitalize;
            }

            .accent-text {
                font-weight: 700;
                &.BUY {
                    color: #1BA259;
                }
                &.SELL {
                    color: #D11325;
                }
            }

            &.SELL {
                background-color: #460D20;
            }

            &.BUY {
                background-color: #0E2E2C;
            }

            &.summary {
                background-color: #17172C;
            }
        }

        .action-back {
            width: 1.6rem;
            height: 1.6rem;
            cursor: pointer;
            position: absolute;
            top: -3.35rem;
            left: 0;
        }
    }

    .actions-block {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .order-view-action {
        font-weight: 500;
        .BUY {
            color: #1BA259;
            font-weight: 500;
        }
        .SELL {
            color: #D11325;
            font-weight: 500;
        }
    }
}

.tx-hash-link {

    .opium-link {
        &.color-scheme {
            &-DARK {
                a {
                    color: $white0;
                }
            }
            &-LIGHT {
                a {
                    color: $darkblue1;
                }
            }
        }
    }
}