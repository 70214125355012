@import '../../../Constants/Styles/constants.scss';
@import '../../../Constants/Styles/colors.scss';

.welcome-popup {
    &.color-scheme-LIGHT {
        .popover-close {
            svg {
              line {
                stroke: $darkblue1;
              }
            }
          }
    }

    &.color-scheme-DARK {
        .popover-close {
            svg {
              line {
                stroke: $white0;
              }
            }
          }
    }

    .check-welcome-popup-title {
        display: flex;
        position: relative;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 27px;
        color: #FFFFFF;
    
        .popover-close {
            position: absolute;
            top: 0;
            right: 0;
            background: transparent;
            outline: none;
            border: 0;

            @media (max-width: 425px) {
                top: -23px;
                right: -20px;

                @media (max-width: 320px) {
                    top: -10px;
                    right: -10px;
                }
            }
        }

        &.color-scheme-DARK {
            color: $white0
        }

        &.color-scheme-LIGHT {
            color: $darkblue1;
        }
    }

    .welcome-popup-content {
        display: flex;
        margin-top: 2rem;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-end;

            @media (max-width: 320px) {
                width: 270px;
            }
        }

        .left-part {
            width: 340px;
            height: 340px;
            border-radius: 10px;
            margin-right: 2rem;
            position: relative;

            .welcome-animation__image {
                object-fit: cover;
                border-radius: 10px;
                width: 100%;
                height: auto;

                @media (max-width: 320px) {
                    width: 260px;
                    height: 260px;
                }

            }

            @media (max-width: 768px) {
                width: 375px;
                height: 375px;

                @media (max-width: 425px) {
                    margin-right: 0;
                    width: 346px;
    
                    @media (max-width: 414px) {
                        width: 332px;
                        height: 332px;

                        @media (max-width: 393px) {
                            width: 310px;
                            height: 310px;
            
                            @media (max-width: 360px) {
                                width: 280px;
                                height: 280px;
            
                                @media (max-width: 320px) {
                                    width: 260px;
                                    height: 260px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .right-part {
            width: 400px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;

            .welcome-list {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                list-style-type: none;

                li {
                    margin: 1.7rem 0;
                    span {
                        text-transform: uppercase;
                        font-weight: 600;
                    }

                    .welcome-arrow-right {
                        transform: rotate(-90deg);
                        width: 17px;
                        height: 17px;
                        object-fit: cover;
                        margin-bottom: -2px;
                    }
                }
            }

            .welcome-btn-actions {
                margin: 2.7rem 0!important;
            }

            .welcome-subtitle {
                display: inline-block;

                img {
                    margin-bottom: -6px;
                }

                @media (max-width: 768px) {
                    margin-top: 2rem;
                }
            }

            @media (max-width: 425px) {
                max-width: 340px;
                width: auto;
            }
        }
    }
}

.check-welcome-popup {
    .modal-content {
        @media (max-width: 320px) {
            padding: 15px!important;
            width: 310px;
        }
    }
}
