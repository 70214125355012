.nft-data-content {
  .nft-data-grid {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    margin-bottom: 1rem;

    &_LIGHT {
      color: #212529;

      .opium-link a {
        color: #212529;
      }
    }
  
    &_DARK {
      color: #FFFFFF;

      .opium-link a {
        color: #FFFFFF;
      }
    }

    @media screen and (max-width: 350px) {
      grid-template-columns: repeat(1, auto);
    }
  }

  .nft-data-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 350px) {
      justify-content: flex-start;
    }
  }

  .nft-data-label {
    font-size: 12px;
    line-height: 16px;
    margin-right: 10px;

    @media screen and (max-width: 425px) {
      margin-right: 5px;
    }

    @media screen and (max-width: 350px) {
      width: 80px;
    }
  }

  .nft-data-value {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    font-weight: bold;

    @media screen and (max-width: 425px) {
      font-weight: normal;
    }
  }

  .opium-link {
    margin-right: 10px;

    a {
      font-weight: bold;

      @media screen and (max-width: 425px) {
        font-weight: normal;
      }
    }
  }
}
