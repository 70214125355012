.btn-sort {
    border: none;
    padding: 0!important;
    border: none!important;
    border-radius: 0!important;
    display: block;

    &-label {
      display: flex;
      align-items: center;
      color: #999BBC;
      font-size: 12px !important;
      text-align: start;
  
      svg {
        width: 25px;
        height: 6px;
  
        &.ascending {
          transform: rotate(180deg);
        }
      
        &.descending {
          transform: rotate(0deg);
        }
      }
    }
  }
