@import '../../Constants/Styles/constants.scss';
@import '../../Constants/Styles/colors.scss';

.modal.Staking__stake-withdraw-modal {
  .modal-dialog {
    width: 100%;
    max-width: 380px;
    .modal-body {
      p {
        line-height: 18px;
      }
    }
  }
}

.staking {
  &__wrapper {
    position: relative;
    overflow: auto;
    height: calc(100vh - 209px);
    width: 100%;
    min-height: 0;

    .experimental-text {

      &.color-scheme-DARK {
        color: white;
      }

      &.color-scheme-LIGHT {
        color: #0A0A1E;
      }

      :first-child {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 5px;
      }
      :last-child {
        width: 50%;
        font-size: 12px;
        line-height: 14px;
      }
      margin: 1rem 0;
    }
  }
  &__title {
      display: none;
      width: 100%;
      margin-bottom: 25px;

      div {
          display: inline-block;
          width: 18px;
          height: 16px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          margin-right: 16px;
      }

      span {
          font-family: Montserrat;
          font-weight: 500;
          font-size: 22px;
          line-height: 20px;
      }
  }

  &__tabs {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 22px;
    max-width: calc(100% - 355px);
    .nav-tabs {
      padding-left: 28px;
      .nav-item {
        font-family: Montserrat;
        font-weight: normal;
        font-size: 12px!important;
        line-height: 12px;
        padding: 4px 0;
        margin-right: 36px!important;
      }
    }

    .tab-content {
      flex: 1;
      min-height: 270px;
      max-height: 270px;
      .tab-pane {
        &.active {
          &.color-scheme {
            &-DARK,
            &-LIGHT {
              background: transparent;
            }
          }
        }
      }
    }
  }

  &-top-line {
    width: 100%;
    border-top: 1px solid #999BBC;

    .content-block {
      align-items: baseline;
      .text-label {
        font-size: 10px;
      }

      .text-content {
        font-size: 11.5px;
      }
    }
  }

  &-tabs-line {
    width: 100%;
    height: 1px;
    background-color: #5D5F7C;
    margin: 12px 0;
  }
}

.page-layout.staking {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100vh - 140px);
  padding-top: 14px;
  .on-chain {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .alert-wrapper {
    display: flex;
    justify-content: center;

    .staking-alert {
      width: 50vw;
      border-radius: 10px;
      text-align: center;

      a {
        padding-left: 5px;
        color: color(white);
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .tab-item-wrap {
    .rewards-wrap {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .mining {
        width: 50%;
      }

      .rewards {
        width: 50%;
      }

      .staked-wrap {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .page-layout.staking {
    width: calc(100% + 16px);
    margin-left: -16px;
    padding-top: 26px;
  }

  .staking {
    &__title {
      display: block;
    }

    &__tabs {
      .nav-tabs {
        padding-left: 18px;
      }
    }

    &__wrapper {
      .experimental-text {
        :last-child {
          width: 100%;
        }
      }
    }

    .FilterControls {
      .btn-selector {
        margin-right: 25px;

        &:after {
          bottom: -4px;
        }
      }
    }
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .page-layout.staking {
    margin-left: 0;
    width: 100%;
  }
}

.shown-content {
  animation: 0.3s linear 0s fadein;
}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@media (max-width: 768px) {
  .staking {
    &__title {
      display: none;
    }
    &__tabs {
      display: none;
      max-width: none;
    }
    &__wrapper {
      max-height: none;

      .experimental-text {
        :last-child {
          width: 70%;
        }
      }
    }

    
  }
  .page-layout.staking {
    margin-left: 0;
    width: 100%;
    padding-top: 0;
    height: calc(100vh - 160px);
  }
}

@media (max-width: 812px) and (orientation: landscape) {
  .page-layout.staking {
    margin-left: 0;
    width: 100%;
    padding: 0;
    .FilterControls {
      .btn-selector {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        margin-right: 15px;
      }
    }
    .ContainerWithCollapse {
      margin-bottom: 7px;
    }
  }
  .staking {
    &__wrapper {
      max-height: none;
    }
    &__title {
      display: none;
    }
  }
}

@media (max-width: 425px) {
  .page-layout.staking {
    height: calc(100vh - 200px);
    .experimental-text {
      :last-child {
        width: 100%;
      }
    }
    }

  .staking-popup-v2 {
    .modal-dialog {
      margin: auto!important;
    }
  }

  .OpiumFieldset {
    .react-numeric-input {
      .OpiumInput {
        text-align: left!important;
      }
    }
  }
  
}

#opium-tabs-component-tab-active-stakings {
  font-size: 14px !important;
  margin-bottom: 10px;
  font-weight: 500;
}

#opium-tabs-component-tab-ended-stakings {
  font-size: 14px !important;
  margin-bottom: 10px;
  font-weight: 500;
}

#opium-tabs-component-tab-experimental-stakings {
  font-size: 14px !important;
  margin-bottom: 10px;
  font-weight: 500;
}


.staking-risk-disclosure-text {
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  line-height: 20px;
  padding: 1rem;
  overflow-y: none;
  b {
    font-weight: 900;
  }
}

.token-analytics-tab-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.token-analytics-tooltip-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 2rem;
}

.token-analytics-chart-tooltip {
  font-size: 13px;
  font-weight: bold;
  line-height: 1.15;

  .chart-tooltip-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    &.token-price {
      color: $pink;
    }
  
    &.lp-price {
      color: #197CD8;
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .chart-tooltip-label {
    margin-right: 10px;
  }
}

.token-analytics-chart-wrapper {
  width: 100%;

  .MultipleLineChart {
    width: 100%;
  }
}

.layout-tabs {

  &.controlled-tabs_DARK {
    .controlled-tab {
      color: #fff;

      &.controlled-tab_is-active {
        border-bottom: 1px solid #fff!important;
      }
    }
  }

  &.controlled-tabs_LIGHT {
    .controlled-tab {
      color: #0A0A1E;

      &.controlled-tab_is-active {
        border-bottom: 1px solid #0A0A1E!important;
      }
    }
  }

  .controlled-tab {
    font-size: 14px!important;
    margin-bottom: 10px;
    font-weight: 500!important;
    border-bottom: 1px solid transparent!important;
    transition: all 0.15s linear;

    &:not(:last-child) {
      margin-right: 2em;
    }
  }
}

.staking-popup-v2 {
  .modal-dialog {
    max-width: 380px;
  }
}