@import '../Constants/Styles/constants.scss';
@import '../Constants/Styles/colors.scss';

.text {
  margin-top: 2rem;
  padding: 20px 30px;
  border: 2px solid black;
}

.widget-title {
  width: 100%;
  text-align: center;
  font-size: fz(l);
  text-transform: uppercase;
  font-weight: bold;
}

.ERROR {
  color: red;
  border-color: red;
}

.SUCCESS {
  color: green;
  border-color: green;
}

.widget {
  &.color-scheme {
    &-DARK {
      .widget-wrap {
        .widget-body {
          color: $white0;
          .description-wrap {
            color: $white0;
          }
        }
      }
    }

    &-LIGHT {
      .widget-wrap {
        .widget-body {
          color: $darkblue1;
          .description-wrap {
            color: $darkblue1;
          }
        }
      }
    }
  }
}

.confirmation-popup {

  &-btns-block {
    display: flex;

    &.btns-between {
      justify-content: space-between;
    }

    &.btns-center {
      justify-content: center;
    }
  } 

  &-text-block {
    padding: 1rem;
    background-color: #999BBC1A;
    border-radius: 5px;
    font-weight: 400!important;

    p {
      margin-bottom: 0!important;
    }
  }

  &-top-info {
    display: flex;
    justify-content: space-between;
  }

}

.error-transaction {
  .modal-header {
    .modal-title {
      color: red
    }
}
}

.pool-link-wrapper {
  display: flex;
  align-items: center;
  color: #999BBC;
  justify-content: flex-start;

  .pool-link-text {
    margin-top: 4px;
  }

  .pool-link {
    display: flex;
    color: #999BBC;

    &:hover {
      text-decoration: none;
    }
  }
}

.btn-action-wide {
  width: 100%;
  display: block;
  background-color: #197CD8!important;
  border-color: #197CD8!important;

  &.hovered {
    &:hover {
      color: #197CD8 !important;
      background-color: transparent !important;
    }
  }

}

.Staking__stake-withdraw-modal {
  .modal-content {
    min-width: 380px;
  }
}

.widget-wrap {
  border-radius: 5rem;

  .widget-header {
    padding: 3rem 0 0;
    border-radius: 20px;

    .header-with-logo {
      width: 100%;
      margin-bottom: 20px;

      &__title {
        width: 100%;
        font-size: 40px;
        color: color(black);
        text-align: center;
      }

      &__logo {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }

    .header-with-text {
      margin-bottom: 20px;

      .text-block {
        padding: 2rem;
        border-radius: 20px;
        text-align: center;
      }
    }

    .default-widget-header {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .title {
        color: color(black);
        font-size: 16px;
        font-weight: 700;
      }

      .text-block {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: color(white);
        cursor: pointer;
      }
    }
  }

  .widget-text {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-bottom: 32px;

    .widget-title {
      margin-bottom: 37px;
    }

    &.down {
      margin-bottom: 25px;
      margin-top: 2rem;
      align-items: flex-start;
      height: auto;
      .alert {
        div {
          line-height: normal;
        }
      }
      .bottom-info-block {
        margin-bottom: 0;
      }
    }

    .alert {
      margin: 0;
      margin-bottom: 35px;
      font-size: 1rem;
    }
  }

  .widget-confirmation-checkbox-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 0 1rem 2rem 2rem;
    .widget-confirmation-checkbox-label {
      font-size: 13px;
      font-weight: 600;
      line-height: normal;
      margin-left: 1rem;
    }
  }

  .widget-body {
    max-width: 778px;
    border-radius: 20px;

    .widget-product-title {
      font-weight: bold;
      font-size: 1.4rem;
      text-transform: uppercase;
    }

    .dropdown {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      .btn {
        &.dropdown-toggle {
          width: 100%;
          margin: 0 1rem;
          z-index: 0;
        }
      }
      .show {
        &.dropdown-menu {
          width: calc(100% - 2rem) !important;
        }
      }
    }

    .btn.btn-primary {
      width: 100%;
    }

    .action-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      padding: 1rem;

      .action-text {
        font-size: 16px;
        font-weight: 700;
        color: color(black)
      }

      .action {
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;

        &.buy {
          color: color(green);
        }

        &.sell {
          color: color(pureRed);
        }
      }
    }

    .widget-quantity {
      .def-number-input {
        width: 100%;
      }
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 15px;
      padding: 0;
      position: relative;

      &:last-of-type {
        margin-bottom: 0;
      }

      // .item-title, .item-value {
      .item-title {
        font-family: "Montserrat", sans-serif;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;

        text-align: left;
        // &:last-child {
        //   text-align: right;
        // }
      }

      .item-value {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
      }
    }

    .description-wrap {
      width: auto;
      margin: 36px 0 40px 0;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      strong {
        color: #197CD8
      }
    }

    .btn-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.no-price {
        justify-content: center;
      }

      &.bidding-wrap {
        margin-bottom: 36px;
      }

      .btn.btn-primary {
        width: auto;
      }

      .btn-price {
        display: flex;
        font-weight: 500;
        flex-direction: column;
        .btn-price-text {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    .widget-tip {
      .item-title {
        display: inline-block;
        margin-left: 14px;
        margin-bottom: 10px;
      }
    }
  }

  .OpiumFieldset {
    &__label {
      font-size: 12px;
    }
    .OpiumInput {
      font-size: 14px;
    }
  }
}

.Popup.widget-attention-popup {
  .modal-dialog {
    max-width: 274px;
  }
}

@media screen and (max-width: 480px) {
  .widget-wrap {
    .widget-text {
      // width: calc(100% + 30px) !important;
      width: 100%;
      margin-left: 0;
      margin-bottom: 20px;
    }
    .widget-header {
      padding: 2rem 0 0;
    }
    .widget-body {
      border-radius: 0;
    }
  }
  .modal-dialog {
    .modal-body {
      padding: 0;
      border: none;

      .widget-wrap {
        width: 100%;
        border: none;
        border-radius: 0;
      }
    }
  }
  .widget-wrap {
    .widget-body {
      padding: 3rem 0 3rem;
      overflow: hidden;

      .bottom-info-block {
        width: 100% !important;
        margin: 0;
        margin-bottom: 35px;
      }
    }
    .widget-text {
      height: auto;
      &.down {
        margin-top: 20px;
        .bottom-info-block {
          margin: 0;
        }
      }
      .widget-title {
        margin-bottom: 20px;
      }
    }
  }

  .Staking__stake-withdraw-modal {
    .modal-dialog {
      margin: auto !important;
    }
  }
}

@media screen and (max-width: 375px) {
  .modal {
    &.attention-popup {
      .modal-dialog {
        .modal-body {
          padding: 0;
          border: none;
        }
      }
    }
  }

  .widget-wrap {
    .widget-body {
      padding-top: 3rem;
    }
  }
}


.maintenance-content-wrapper {
  line-height: 20px;
   & > div {
    width: 100%;
    display: flex; 
    justify-content: center;
    margin-top: 2rem;
  }
}

.OpiumFieldset__maxBtn {
  &.color-scheme-LIGHT {
    background-color: $white0!important;
    &:hover {
      background-color: #212529!important;
    }
  }
}