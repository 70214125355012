@import '../../Constants/Styles/constants.scss';
@import '../../Constants/Styles/colors.scss';

.positions-wrapper {
  position: relative;
  overflow: auto;
  height: calc(100vh - 355px);
  width: 100%;
  .ContainerWithCollapse {
    margin-bottom: 16px;
    @media (max-width: 425px) {
      margin-bottom: 25px;
    }
  }

  @media only screen and (device-width: 1680px) and (device-height: 1050px) {
    height: calc(100vh - 190px);
  }

  @media screen and (max-width: 425px) {
    height: calc(100vh - 300px);
  }
}

.position-block {
  display: block;
  justify-content: center;
  align-items: center;
  min-height: 88px;
  padding: 0 15px;
  .datetime {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    transform: rotate(270deg);
  }

  .datetime-mobile {
    display: none;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .logo {
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      min-width: 101px;
      height: 63px;
    }
  }

  .description-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    height: 100%;

    &.description-low-block {
      margin-top: 15px;

       @media screen and (max-width: 1536px) {
          margin-top: 10px;
        }
    }

    .opium-link {
      margin-top: 2px;
      a {
        font-size: 12px;
      }
    }

    .planned-short-position-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;

      .position-type {
        margin-top: 0;
      }
    }

    .position-type {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      margin-top: 10px;

      &.green {
        color: $lightgreen;
      }

      &.red {
        color: $pink;
      }

      &.blue {
        color: $blue1;
      }
    }

    .content-block {
      margin-bottom: 8px;

      .text-label {
        font-size: 12px;
        line-height: 15px;
      }

      .text-content {
        font-size: 14px;
        line-height: 15px;

        .execute-block {
          display: flex;
          flex-direction: column;

          .position-execute-btn {
            margin: 5px 0;
            padding: 1px 5px!important;
          }
        }

        .balance-helper-block {
          display: flex;
          justify-content: flex-start;

          .position-clear-btn {
            padding: 1px 5px!important;
            margin-left: 10px;
          }
        }
      }

      &.horizontal {
        justify-content: flex-start;
        align-items: baseline;

        .text-content {
          width: 100%;
        }
        .text-label {
          margin-right: 5px;
          white-space: nowrap;
        }
      }
    }

    .withdraw-type {
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      .title{
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      }
      .text-content{ 
        line-height: 12px;
        font-size: 10px;
        padding: 11px 0px 10px 0px;
        
      }
      .button-wrap {
        display: flex;
        flex-direction: row;
        padding: 0px 0px 20px 0px;
      }
    }
    

    .title {
      font-weight: 900;
      font-size: 14px;
      line-height: 15px;
    }

    .ticker {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      // color: #999BBC;
    }

    .created-time {
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
    }

    .description {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      margin-top: 12px;
      border: 0;
      padding: 0;
      background-color: transparent;
    }

    .color-scheme {
      &-DARK {
        a {
          color: $white0
        }
      }

      &-LIGHT {
        a {
          color: $darkblue1
        }
      }
    }
  }

  .position-address-actions {
    display: flex;
    cursor: pointer;
    flex-direction: row;

    .v2-link-btn {
      display: flex;
      flex-direction: row;
      margin-left: 25px;
    }

    .v2-link-margin {
      margin-left: 25px;
    }

    .position-address-plus {
      display: flex;
      align-items: center;
      font-size: 16px;
      .position-metamask-logo {
        width: 15px;
        height: auto;
      }
    }
  }

  .total-size-block {
    //width: $cell4;
  }

  .reward-block {
    height: 100%;
    display: flex;
    justify-content: center;
    .description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
  }

  .position-status {
    font-size: 12px;
    line-height: 16px;

    .position-status-date {
      font-weight: bold;
    }
  }

  .action-btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    width: 85%;

    .opiumBtn, .popover__disabled-button {
      margin: .5rem 0;
      width: 100%;
    }

    // .opiumBtn {
    //   &.position-trade-btn {
    //     &.LIGHT {
    //       &:hover {
    //         background-color: aqua;
    //       }
    //     }
    //   }
    // }

    .popover__disabled-button button {
      margin: 0;
    }

    img {
      width: 16px;
      margin: auto;
    }

    a {
      width: 100%;
    }
  }
}

.popover__disabled-button button {
  margin: 0;
  &:first-child {
    margin: 0;
  }
}

.position-cancel {
  
  .actions-button {
    display: flex;
  }
}

// OLD STYLES

.positions-trigger {
  position: absolute;
  top: 0;
  right: 0;
}

.positions.popover {
  background-color: transparent;

  & .popover-body.color-scheme-LIGHT {
    padding: 0;
    height: auto;
    background-color: $gray7;
    border-color: transparent;
    border-radius: 33px;
    overflow: hidden;
    // color: color(gray3);
  }

  & .popover-body.color-scheme-DARK {
    padding: 0;
    background-color: $darkblue1;
    border-color: transparent;
    border-radius: 33px;
    overflow: hidden;
    // color: white;
  }

  &.color-scheme-LIGHT {
    &.bs-popover-bottom > .arrow::after {
      border-bottom-color: $gray7;
    }
    &.bs-popover-right > .arrow::after {
      border-right-color: $gray7;
    }
    &.bs-popover-left > .arrow::after {
      border-left-color: $gray7;
    }
    &.bs-popover-top > .arrow::after {
      border-top-color: $gray7;
    }
  }

  &.color-scheme-DARK {
    &.bs-popover-bottom > .arrow::after {
      border-bottom-color: $darkblue1;
    }
    &.bs-popover-right > .arrow::after {
      border-right-color: $darkblue1;
    }
    &.bs-popover-left > .arrow::after {
      border-left-color: $darkblue1;
    }
    &.bs-popover-top > .arrow::after {
      border-top-color: $darkblue1;
    }
  }
}

$cell1: 45%;
$cell2: 18%;
$cell3: 37%;

.positions-table {
  font-size: 10px;

   & .cell {
      &:nth-child(1) {
        width: $cell1;
        & span {
          text-align: center;
          display: block;
          width: 90%;
          white-space: normal;
        }
      }
      &:nth-child(2) {
         width: $cell2;
      }
      &:nth-child(3) {
         width: $cell3;
      }
      &:last-child {
         justify-content: center;
         div {
           width: auto;
         }
      }
   }

  & .table-head-cell {
    font-size: 10px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
  }

  & .table-cell {
    padding: indent(1.5) indent(0);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .color-scheme-LIGHT .table-cell {
    color: color(gray3);
  }
  & .color-scheme-DARK .table-cell {
    color: white;
  }

  & .position-trade-btn {
    padding: indent(0.5) indent(1.5);
    margin: indent(0.5) indent(1.5);
    font-size: 9px;
    text-transform: lowercase;
  }
}

@media screen and (max-width: 1024px) {
  .positions.popover,
  .positions-trigger {
    display: none;
  }
  .position-block {

    .col {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .position-address-actions {
    &.v2 {
      flex-direction: column;

      .v2-link-btn {
        margin-top: 2px;
        margin-left: 0;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .position-block {
    .action-btns {
      flex-direction: row;
    }
  }
}

@media screen and (max-width: 768px) {
  .position-block {
    padding: 15px 0;
    .description-block {
      margin-bottom: 20px;
    }

    .action-btns {
      flex-direction: column;
      .withdrawal-checkbox-wrapper {
        margin-bottom: 20px;
      }
    }

    .col {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .withdraw-type {
    margin-top: 20px;
  }

  .position-address-actions {
    &.v2 {
      flex-direction: row;

      .v2-link-btn {
        margin-top: 0;
        margin-left: 25px;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .position-address-actions {
    &.v2 {
      flex-direction: column;

      .v2-link-btn {
        margin-top: 2px;
        margin-left: 0;
      }
    }
  }
}


#opium-tabs-component-tab-active-positions {
  font-size: 12px !important;
  margin-bottom: 10px;
}

#opium-tabs-component-tab-ended-positions {
  font-size: 12px !important;
  margin-bottom: 10px;
}

#opium-tabs-component-tab-subgraph-sync-positions {
  font-size: 12px !important;
  margin-bottom: 10px;
}



.schedule-withdraw-loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  & > * {
    margin-right: 10px;
  }
}

.withdrawal-checkbox-wrapper {
  margin-top: 10px;
  font-size: 14px;

  label {
    padding-left: 20px !important;
  }
}

.automatic-withdrawal-tooltip-wrapper {
  .automatic-withdrawal-bold-text {
    font-weight: 700;
  }
  & > * {
    margin-bottom: 10px;
  }
  div {
    display: inline-block;
  }
  .red {
    color: $pink;
  }
  .green {
    color: $lightgreen;
  }
  .blue {
    color: $blue1;
  }
}
.withdrawal-checkbox-label {
  font-size: 12px;
  & > * {
    display: inline-block;
  }
  .red {
    color: $pink;
  }
  .green {
    color: $lightgreen;
  }

  &.label-cursor-default {
    cursor: default;
  }
}

.col-no-padding {
  padding: 0!important
}
