@import '../../Constants/Styles/constants.scss';

$cell1: 15%;
$cell2: 20%;
$cell3: 25%;
$cell4: 20%;
$cell5: 10%;
$cell6: 10%;

.positions-mobile {
  position: relative;
  height: calc(100vh - 209px);
  width: 100%;
  min-height: 0;
  padding-top: 14px;

  @media (max-width: 1200px) {
    padding-top: 16px;

    @media (max-width: 768px) {
      padding-top: 3px;
      height: calc(100vh - 165px);
    }

    @media (max-width: 425px) {
      height: calc(100vh - 200px);
    }
  }

  .position-collateral {
    padding: 0.7rem 0;
    border-radius: 10px;

    .collateral-label {
      color: #999BBC;
      margin-bottom: 5px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    .collateral-value {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;

      &.color-scheme-DARK {
        color: #fff;
      }

      &.color-scheme-LIGHT {
        color: #0C0C23;
      }
    }
  }

  * {
    line-height: normal;
  }
  &.mobile {
    .positions-table-mobile.custom-table {
      .table-head {
        display: none;
      }
    }
  }

  .positions-mobile {
    &__message {
      display: flex;
      height: 65vh;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        p {
          font-family: Montserrat;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
          display: flex;
          align-items: center;
        }
  
        span {
          font-weight: 400;
          font-size: 12px;
          font-family: Montserrat;
          line-height: 15px;
        }
      }
    }
  }

  &.color-scheme {
    &-DARK {
      .positions-mobile {
        &__message {
          p, span{
            color: #fff;
          }
        }
      }
    }
    &-LIGHT {
      .positions-mobile {
        &__message {
          p, span{
            color: #0A0A1E;
          }
        }
      }
    }
  }
}

.positions-table-mobile.custom-table {
  
  .table-body {
    .table-row {
      flex-direction: row;
    }
  }
  & .cell {
    &:nth-child(1) {
      width: $cell1;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    &:nth-child(2) {
      width: $cell2;
      & span {
        display: block;
        width: 90%;
        white-space: normal;
      }
    }
    &:nth-child(3) {
      width: $cell3;
    }
    &:nth-child(4) {
      width: $cell4;
    }
    &:nth-child(5) {
      padding: 0;
      justify-content: center;
      width: $cell5;
    }
    &:nth-child(6) {
      padding: 0;
      justify-content: center;
      width: $cell6;
    }
  }

  & .table-head-cell {
    text-transform: uppercase;
    display: flex;
    justify-content: flex-start;
  }

  & .table-cell {
    padding: indent(1.5) indent(2);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 3.5rem;
  }

  & .color-scheme-LIGHT .table-cell {
    color: color(gray3);
  }
  & .color-scheme-DARK .table-cell {
    color: white;
  }

  & .position-trade-btn {
    text-transform: lowercase;
  }

  & .table-row {
    // padding: indent(2) indent(0);
    .table-cell {
      .btn {
        text-transform: uppercase;
        min-width: 82px;
        padding: indent(.5) indent(1);
        margin: 0;
        width: 100%;
        .btn__label {
          white-space: pre-wrap;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  $cell1m: 15%;
  $cell2m: 35%;
  $cell3m: 25%;
  $cell4m: 0%;
  $cell5m: 17%;
  $cell6m: 17%;

  .positions-table-mobile.custom-table {
    & .table-head-cell {
      padding-left: 0;
      padding-right: 0;
      &:nth-child(1) {
        width: $cell1m;
      }
      &:nth-child(2) {
        width: $cell2m;
      }
      &:nth-child(3) {
        width: $cell3m;
      }
      &:nth-child(4) {
        width: $cell4m;
        display: none;
      }
      &:nth-child(5) {
        width: $cell5m;
      }
      &:nth-child(6) {
        width: $cell6m;
      }
    }

    .table-row {
      .table-cell {
        .btn {
          display: block;
        }
      }
    }

    .cell {
      &:nth-child(1) {
        width: $cell1m;
      }
      &:nth-child(2) {
        width: $cell2m;
      }
      &:nth-child(3) {
        width: $cell3m;
      }
      &:nth-child(4) {
        width: $cell4m;
        display: none;
      }
      &:nth-child(5) {
        width: $cell5m;
      }
      &:nth-child(6) {
        width: $cell6m;
      }
    }

    .table-body {
      .table-row {
        flex-direction: row;
      }
    }
  }
}


@media (max-width: 480px) {
  $cell1sm: 15%;
  $cell2sm: 35%;
  $cell3sm: 25%;
  $cell4sm: 0%;
  $cell5sm: 35%;
  
  .positions-table-mobile.custom-table {
    * {
      text-align: center;
    }
    .table-cell {
      justify-content: center;
      div {
        justify-content: center;
      }
    }
    .table-body {
      .table-row {
        margin-bottom: 10px;
        .table-cell {
          .btn {
            display: block;
            min-width: 130px;
            margin-bottom: 16px;
            div {
              justify-content: center;
            }
          }
          &:nth-child(2) {
            justify-content: center;
          }
          &:nth-child(3) {
            display: block;
            .text-block {
              width: 100%;
            }
          }
          &:nth-child(4) {
            justify-content: center;
          }
        }
      }
    }
    & .table-head-cell {
      padding-left: 0;
      padding-right: 0;
      &:nth-child(1) {
        width: $cell1sm;
      }
      &:nth-child(2) {
        width: $cell2sm;
      }
      &:nth-child(3) {
        width: $cell3sm;
      }
      &:nth-child(4) {
        width: $cell4sm;
      }
      &:nth-child(5) {
        width: $cell5sm;
      }
    }

    .table-body {
      .table-row {
        flex-direction: column;
        text-align: center;
        .table-cell {
          width: 100%;
          &:nth-child(1) {
            width: 100%;
          }
          &:nth-child(2) {
            justify-content: flex-start;
            width: 100%;
          }
          &:nth-child(3) {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 20px;
          }
          &:nth-child(4) {
            width: 100%;
          }
          &:nth-child(5) {
            width: 100%;
          }
        }
      }
    }

    & .table-row .table-cell {
      & .btn {
        padding: indent(1) indent(2);
        margin: indent(0) indent(0);
      }
    }

    .cell {
      &:nth-child(1) {
        img {
          max-width: 98px;
          max-height: 98px;
        }
      }
      &:nth-child(2) {
        span {
          text-align: left;
        }
      }
      &:nth-child(3) {
        span {
          text-align: left;
        }
      }
    }
  }
}
