.ContainerWithCollapse {
    .accordion {
        border: 1px solid transparent;
        background-color: transparent;
        border-radius: 10px;

        transition: border-color .5s ease, background-color .3s ease;
    }
    .card {
        background-color: transparent;
        border-color: transparent;
        &-header {
            padding: 0;
            background-color: transparent;
            border-color: transparent;
        }
        &-body {
            padding: 0;
        }
    }
    
    &.color-scheme {
        &-DARK {
            color: #fff;
            &:hover {
                .accordion {
                    background-color: #0A0A1E;
                }
            }
        }
    }
}
