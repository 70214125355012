@import '../../../../Constants/Styles/colors.scss';

.nft-content-error {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &__text {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0.5rem;
    color: $pink;
  }
}
