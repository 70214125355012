@import '../../Constants/Styles/constants.scss';
@import '../../Constants/Styles/colors.scss';

.header-settings-wrapper-mobile {
  display: none;
}
.OpiumPopup{
  z-index: 10000;
}
.header-settings-wrapper {
  display: flex;
  flex-direction: row;
  .overlay-trigger {
    margin-right: 20px;
  }
  &.DARK {
    .overlay-trigger {
      &:hover {
        &:before {
          opacity: 1;
          background-color: rgba(color(gray6), .2);
        }
      }
    }
  }
  &.LIGHT {
    .overlay-trigger {
      &:hover {
        &:before {
          opacity: 1;
          background-color: rgba(color(blue1), .2);
        }
      }
    }
  }
  img {
    width: auto;
  }
}
.buttons{
  display: flex;
  justify-content: center;
}
.overlay-trigger {
  position: relative;
  background-color: transparent;
  height: 20px;
  width: 26px;
  color: $white0;
  display: inline-flex;
  vertical-align: top;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translateZ(-1px);
    width: 27px;
    height: 27px;
    border-radius: 100%;
    z-index: -1;
    opacity: 0;
    pointer-events: none;

    transition: opacity .3s ease;
  }
  &:hover {
    cursor: pointer;
  }
  svg {
    &.fa-question-circle {
      width: 22px !important;
      height: 22px !important;
    }
  }
  &.custom {
    &-DARK {
      svg * {
        fill: $gray6;
      }
    }
    &-LIGHT {
      svg * {
        fill: $gray2;
      }
    }
  }
}
.popup-text{
  font-weight: 500;
}

.settings-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
  padding: 30px 20px 23px 20px;
  z-index: 100;
  box-shadow: (75, 75, 78, 0.15) 0px 0px 12px;

  .product-link-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 13px;
    p {
      font-family: 'Montserrat', sans-serif;
      font-size: 10px;
      line-height: 12px;
    }
  }

  .legal-pages-block {
    display: flex;
    flex-direction: row;
    .left-column {
      width: 50%;
      display: flex;
      flex-direction: column;
    }

    .right-column {
      width: 50%;
      display: flex;
      flex-direction: column;
    }
    a {
      color: #ffffff;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      margin-right: 5px;
      margin-bottom: 4px;
    }
  }

  .legal-pages-LIGHT {
    a {
      color: #78787a;
    }
  }

  ul {
    &.legal-pages {
      li {
        a {
          font-family: "Montserrat", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 12px;
        }
      }
    }

  }

  .additional-links {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
    &.color-scheme {
      &-DARK {
        a {
          color: $white0;
          &:hover {
            text-decoration: underline;
          }
        }

      }

      &-LIGHT {

        a {
          color: $darkblue1;
          &:hover {
            text-decoration: underline;
          }
        }

      }
    }
  }

  // &.info {
    // padding: 1rem;
    // height: auto;
    &__icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 12px;
      img {
        width: 100%;
        // height: 100%;
        // max-width: 100%;
        max-height: 100%;
      }
    }
    ul {
      margin: 0;
      li {
        a {
          display: flex;

          align-items: center;
          margin-bottom: 8px;
          min-height: 16px;

          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          text-decoration: none;
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      &.small {
        a {
          font-weight: normal;
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
    &.color-scheme {
      &-DARK {
        ul {
          li {
            a {
              color: color(gray9);
              &:hover {
                color: color(gray8);
              }
            }
          }
        }
      }
      &-LIGHT {
        ul {
          li {
            a {
              color: color(gray3);
              // &:hover {
              //   background-color: rgba(color(blue1), .2);
              // }
            }
          }
        }
      }
    }
  // }
  .RadioButton {
    white-space: nowrap;
    margin-right: 30px;
  }
  .settings-subtitle {
    margin-bottom: -1px;
  }
}

.settings-socials {
  padding: 9px 5px;
  margin-bottom: 15px;
  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    li {
      display: inline-block;
      font-size: 0;
      margin-right: 22px;

      &:last-child {
        margin-right: 0;
      }

      a {
        margin-bottom: 0;
        img {
          width: 19px;
        }
      }
    }
  }
}

.settings.popover {
  width: 100%;
  max-width: 248px;
  background-color: transparent;
  padding: 0;
  & .popover-body.color-scheme-LIGHT {
    background-color: $white1;
    border-color: transparent;
    overflow: hidden;
    color: color(gray3);
  }

  & .popover-body.color-scheme-DARK {
    background-color: $darkblue1;
    border-color: transparent;
    overflow: hidden;
    color: $white0;
  }

  &.color-scheme-LIGHT {
    &.bs-popover-bottom > .arrow::after {
      border-bottom-color: $white1;
    }
    &.bs-popover-right > .arrow::after {
      border-right-color: $white1;
    }
    &.bs-popover-left > .arrow::after {
      border-left-color: $white1;
    }
    &.bs-popover-top > .arrow::after {
      border-top-color: $white1;
    }
    .popover {
      &-title {
        .popover-close {
          svg {
            line {
              stroke: $darkblue1;
            }
          }
        }
      }
    }
  }

  &.color-scheme-DARK {
    &.bs-popover-bottom > .arrow::after {
      border-bottom-color: $darkblue1;
    }
    &.bs-popover-right > .arrow::after {
      border-right-color: $darkblue1;
    }
    &.bs-popover-left > .arrow::after {
      border-left-color: $darkblue1;
    }
    &.bs-popover-top > .arrow::after {
      border-top-color: $darkblue1;
    }
    .popover {
      &-title {
        .popover-close {
          svg {
            line {
              stroke: $white0;
            }
          }
        }
      }
    }
  }
  .popover {
    &-title {
      position: relative;
      width: 100%;

      font-weight: 500;
      font-size: 24px;
      line-height: 29px;

      margin-bottom: 22px;
      .popover-close {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background: transparent;
        outline: none;
        border: 0;
      }
    }
  }
}

.theme-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.language-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.switcher-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  margin-bottom: 7px;
}
.wallet-address {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 640px), (max-width: 812px) and (orientation: landscape) {
  .header-settings-wrapper {
    display: none;
  }
  .header-settings-wrapper-mobile {
    display: flex;
  }

  .settings {
    &.popover {
      max-width: 195px;
      .popover-body {
        padding: 30px 16px;
        padding-bottom: 16px;
      }
    }
  }

  .switcher-wrapper {
    margin-bottom: 18px;
  }

  .settings-wrapper {
    // padding: 30px 16px;
    padding: 0;
    margin-bottom: 12px;

    &.info {
      ul {
        li {
          margin-bottom: 16px;
        }

        &.small {
          li {
            margin-bottom: 8px;
          }
        }
      }
    }

    .RadioButton {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;

      padding-left: 14px;
      margin-right: 23px;
    }
    .settings-subtitle {
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      margin-bottom: 17px;
    }
  }
}

@media (max-width: 812px) and (orientation: landscape) {
  .settings {
    &.popover {
      max-width: 400px;
    }
    .popover-body {
      display: flex;
      justify-content: space-between;
    }
  }
}
